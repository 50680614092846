import React from 'react'
import PropTypes from 'prop-types'
import axios from "axios"
import CheckVoucherForm from './CheckVoucherForm'
import { Redirect } from 'react-router'
import ProgressBar from "../../shared/ProgressBar/ProgressBar";
import SimplePopUp from '../../shared/SimplePopUp/SimplePopUp'
import SelectBills from './SelectBills'
import {Switch, Grid,Button,TextField,FormControlLabel} from '@material-ui/core'
import styles from './CheckVoucher.module.css'
import moment from 'moment'
class CheckVoucherNewAndUpdate extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      model: 'Check Voucher',
      checkVouchers: [],
      banks: [],
      errorMessages: {},
      load: false,
      bank: {},
      expenseType: {},
      expenseTypes: [],
      payees: [],
      payee: {},
      branches: [],
      branch: {},
      inputTax: {},
      withHoldingTax: {},
      accounts: [],
      contentBank: {},
      forEditCheckVoucherContent: [],
      fordeleteCheckVoucherContent: [],
      checkVoucherContent: [],
      initialCheckVoucherContent: { account_code: '', account: {}, amount_debit: 0, amount_credit: 0 },
      checkVoucher: {
        id: '',
        date_of_check: new Date().toLocaleDateString('fr-Ca'),
        check_no: '',
        amount: '',
        remarks: '',
        reference_no: '',
        bank_id: '',
        taxable: false,
        branch_id: '',
        prefix: localStorage.getItem('checkvoucher_prefix')?(localStorage.getItem('checkvoucher_prefix')):'CV' ,
        postfix: '',
        payee_id: '',
        journal_id: '',
        post_status: '',
      },
      redirect: false,
      totalDigit: 8,
      withInputTax: true,
      withHoldingTaxCode: '20410',
      fromBills: false,
      billsInputTax : 0,
      selectedBills: [],
      selectedBillIds: [],
      openBillsPopUp: false,
      bills: [],
      billInputTax: false,
      billTaxable: true,
      due_at_from: moment().startOf('month').format('YYYY-MM-DD'),
      due_at_to: moment().endOf('month').format('YYYY-MM-DD'),
      accountsPayable: {},
      billContents: [],
      fromPayeeShow: false
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleAutocomplete = this.handleAutocomplete.bind(this)
    this.handleAutocompleteSearch = this.handleAutocompleteSearch.bind(this)
    this.amountChange = this.amountChange.bind(this)
    this.handleSwitch = this.handleSwitch.bind(this)
    this.addCheckVoucherContent = this.addCheckVoucherContent.bind(this)
    this.handleCheckVoucherContentChange = this.handleCheckVoucherContentChange.bind(this)
    this.removeCheckVoucherContent = this.removeCheckVoucherContent.bind(this)
    this.populateContent = this.populateContent.bind(this)
    this.handleContentAutocomplete = this.handleContentAutocomplete.bind(this)
    this.handleCheckVoucherContentAmount = this.handleCheckVoucherContentAmount.bind(this)
    this.backToIndex = this.backToIndex.bind(this)
    this.handleInputTax=this.handleInputTax.bind(this)
    this.openSelectBills = this.openSelectBills.bind(this)
    this.closeBills = this.closeBills.bind(this)
    this.selectAllBills = this.selectAllBills.bind(this)
    this.handleCheckBills = this.handleCheckBills.bind(this)
    this.setAmt = this.setAmt.bind(this)
    this.handleBillInputTax = this.handleBillInputTax.bind(this)
    this.handleBillSwitch = this.handleBillSwitch.bind(this)
    this.filter = this.filter.bind(this)
    this.handleDueDate = this.handleDueDate.bind(this)
    this.getReferenceNo=this.getReferenceNo.bind(this)
    this.padFix = this.padFix.bind(this)
    this.removeBill = this.removeBill.bind(this)
  }


  componentDidMount() {
    if (this.props.match.params.id !== undefined) {
      if (this.props.location.pathname.includes("payees")) {
        this.setState({fromPayeeShow: true})
        axios({
          method: 'get',
          url: '/v1/employees/profiles',
          headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        }).then(resp => {
          if(resp.data.branch_id){
            axios({
              method: 'get',
              url: '/v1/branches/'+resp.data.branch_id,
              headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
            }).then(resp => {
              this.setState({branch: resp.data})
              this.getReferenceNo()
            })
          }
        })
          axios({
            method: 'get',
            url: '/v1/payees/' + this.props.match.params.id,
            headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
          }).then(resp => {
            this.setState({ payee: resp.data })
            this.loadCheckVoucher('/v1/check_vouchers/search?&sort_by[date_of_check]=desc')
          })
      } else {
        axios({
          method: 'get',
          url: '/v1/check_vouchers/' + this.props.match.params.id,
          headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        }).then(resp => {
         var item = resp.data
          this.setState({ expenseType: resp.data.expense_type })
          this.setState({ bank: resp.data.bank })
          this.setState({ payee: resp.data.payee })
          this.setState({ branch: resp.data.branch })
          this.setState({selectedBillIds:resp.data.bill_ids})
          this.setState({withInputTax: resp.data.with_input_vat})
          var contentbank = {}
            axios({
              method: 'get',
              url: '/v1/banks/' + resp.data.bank.id,
              headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
            }).then(resp => {
              contentbank = resp.data.account
              this.setState({ contentBank: contentbank })
            })
            axios({
              method: 'get',
              url: '/v1/check_vouchers/' + this.props.match.params.id + '/bills',
              headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
            }).then(response => {
              
              this.setState({ selectedBills: response.data })
              
            })
          axios({
            method: 'get',
            url: '/v1/check_vouchers/' + this.props.match.params.id + '/check_voucher_contents',
            headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
          }).then(resp => {
            const newData = resp.data.map((item) => ({

              id: item.id,
              account_code: item.account_code, account: item.account, amount_debit: parseFloat(item.amount_debit).toFixed(2), amount_credit: parseFloat(item.amount_credit).toFixed(2)
            }))
            this.setState({ checkVoucherContent: newData })
           if( resp.data.filter(item => item.account_code===this.state.withHoldingTaxCode).length>0)
           {
             item['taxable']=true
           }
           this.setState({checkVoucher: item})
            this.loadCheckVoucher('/v1/check_vouchers/search?&sort_by[date_of_check]=desc')
          })
        })
      }
    }
    else if (this.props.match.params.bill_id !== undefined) {
      this.setState({fromBills: true})
      
      this.setState({selectedBillIds: [this.props.match.params.bill_id]})
      axios({
        method: 'get',
        url: '/v1/employees/profiles',
        headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
      }).then(resp => {
        if (resp.data.branch_id) {
          axios({
            method: 'get',
            url: '/v1/branches/' + resp.data.branch_id,
            headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
          }).then(resp => {
            this.setState({ branch: resp.data })
            this.getReferenceNo()
          })
        }
      })
      axios({
        method: 'get',
        url: '/v1/bills/' + this.props.match.params.bill_id,
        headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
      }).then(resp => {
        var item = resp.data
        item['id']=''
        item['remarks']= resp.data.remarks + "                     Bill # " + resp.data.reference_no
        item['date_of_check']= new Date().toLocaleDateString('fr-Ca')
        this.setState({ expenseType: resp.data.expense_type })
        this.setState({ payee: resp.data.payee })
        this.setState({ branch: resp.data.branch })
        this.setState({withInputTax: !resp.data.with_input_vat})
        item['taxable'] = !resp.data.taxable
        item['amount']=parseFloat(item.amount).toFixed(2)
        axios({
          method: 'get',
          url: '/v1/bills/' + this.props.match.params.bill_id + '/contents',
          headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        }).then(resp => {
          const newData = []
          resp.data.map((item) => {
            if (item.account.name.includes("Accounts Payable")) {
              var newItem = {
                id: item.id,
                account_code: item.account_code,
                account: item.account,
                amount_debit: parseFloat(item.amount_credit).toFixed(2),
                amount_credit: parseFloat(item.amount_debit).toFixed(2)
              }
              newData.push(newItem)
            }
          })
          this.setState({ checkVoucherContent: newData })
          this.setState({ checkVoucher: item })
          this.loadCheckVoucher('/v1/check_vouchers/search?&sort_by[date_of_check]=desc')
        })
      })
    }
    else {
        axios({
          method: 'get',
          url: '/v1/employees/profiles',
          headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        }).then(resp => {
          if(resp.data.branch_id){
            axios({
              method: 'get',
              url: '/v1/branches/'+resp.data.branch_id,
              headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
            }).then(resp => {
              this.setState({branch: resp.data})
              this.getReferenceNo()
            })
          }
          
          this.loadCheckVoucher('/v1/check_vouchers/search')
        })
    }
  }

  loadCheckVoucher(url) {
    axios({
      method: 'get',
      url: url,
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ checkVouchers: resp.data.check_vouchers })
    })
    axios({
      method: 'get',
      url: '/v1/banks/search?per_page=1000',
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ banks: resp.data.banks })
    })
    axios({
      method: 'get',
      url: '/v1/payees/search',
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ payees: resp.data.payees })
    })
    axios({
      method: 'get',
      url: '/v1/branches',
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ branches: resp.data })
    })
    axios({
      method: 'get',
      url: '/v1/check_voucher_expense_types',
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ expenseTypes: resp.data })
      
    })
    axios({
      method: 'get',
      url: '/v1/accounts/search?per_page=10000',
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ accounts: resp.data.accounts })
     
    })
    axios({
      method: 'get',
      url: '/v1/accounts/search?name=Input',
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ inputTax: resp.data.accounts.length>0?(resp.data.accounts[0]):({}) })
      
    })
    axios({
      method: 'get',
      url: '/v1/accounts/search?name=Withholding tax - expanded',
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({
        withHoldingTax: {
          account_code: resp.data.accounts.length>0?(resp.data.accounts[0].code):'',
          account: resp.data.accounts.length>0?resp.data.accounts[0]:'',
          amount_debit: 0,
          amount_credit: 0
        }
      })
    })
    this.setState({ load: true })
  }

  handleInputChange(e) {
    if(e.target.name==="reference_no"){
      this.setState({
        checkVoucher: {
          ...this.state.checkVoucher,
          reference_no: this.padFix(e.target.value)[0]
        }
      })
    }else{
    this.setState({
      checkVoucher: {
        ...this.state.checkVoucher,
        [e.target.name]: e.target.value
      }
    })
  }
  }

  setAmt(value){
    this.setState({
      checkVoucher: {
        ...this.state.checkVoucher,
        amount: value,
        taxable: false,
      }
    })
  }

  amountChange(value) {
    var index = this.state.checkVoucherContent.indexOf(this.state.checkVoucherContent.filter(item=>item.account_code===this.state.contentBank.code)[0])
    this.setState({
      checkVoucher: {
        ...this.state.checkVoucher,
        amount: value,
        taxable: false,
      }
    })
    if (this.state.bank !== undefined) {
      this.populateContent('amount', parseFloat(value).toFixed(2), this.state.inputTax ? (this.state.inputTax) : null)
    }
    if(this.state.checkVoucher.taxable && index!==-1){ 
    this.removeCheckVoucherContent(index,this.state.checkVoucherContent.filter(item=>item.account_code===this.state.withHoldingTax.account_code)[0])
   }
  }



  handleAutocomplete(event, values) {
    if (event.target.id.includes("payee")) {
      this.setState({ payee: values })
    } else if (event.target.id.includes("bank")) {
      var contentbank = {}
      if (values !== null) {
          contentbank = values.account
          this.populateContent('bank', contentbank ? (contentbank) : '')
          this.setState({ contentBank: contentbank })
          axios({
            method: 'get',
            url: '/v1/check_vouchers/search?bank_id=' + values.id+'&sort_by[date_of_check]=desc',
            headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
          }).then(resp => {
            var checkvouchers = resp.data.check_vouchers.sort(
              (a, b) => new Date(a.date_of_check).getTime() - new Date(b.date_of_check).getTime()
            ).reverse()
            this.setState({
              checkVoucher: {
                ...this.state.checkVoucher,
                check_no: (parseInt(checkvouchers.length > 0 ? (checkvouchers[0].check_no?checkvouchers[0].check_no:0) : (0)) + 1)
              }
            })
          
        })
        this.setState({ bank: values })

      } else {
        this.setState({ contentBank: {} })
      }


    } else if (event.target.id.includes("expenseType")) {
      this.setState({ expenseType: values })
      if(values!==null){

      
    var index = this.state.checkVoucherContent.indexOf(this.state.checkVoucherContent.filter(item=>item.account_code===this.state.contentBank.code)[0])
    var indexTax = this.state.checkVoucherContent.indexOf(this.state.checkVoucherContent.filter(item=>item.account_code===this.state.withHoldingTax.account_code)[0])
    var initialAmount = 0
     if(this.state.checkVoucher.taxable){
      if(parseFloat(values.multiplier)>0){
        initialAmount = parseFloat(this.state.checkVoucherContent[index]['amount_credit']) + parseFloat(this.state.checkVoucherContent[indexTax]['amount_credit'])
        const updatedCVContents = this.state.checkVoucherContent.filter(item => item.account_code !== this.state.withHoldingTax.account_code)
        updatedCVContents[index]["amount_credit"]= parseFloat(initialAmount - (initialAmount/1.12*parseFloat(values.multiplier))).toFixed(2)
        var item=this.state.withHoldingTax
        item['amount_credit']=  parseFloat(initialAmount/1.12 * parseFloat(values.multiplier)).toFixed(2)
        this.setState({checkVoucherContent: [...updatedCVContents, item]})
        this.setState({
          checkVoucher:{
            ...this.state.checkVoucher,
            amount: parseFloat(initialAmount - (initialAmount/1.12*parseFloat(values.multiplier))).toFixed(2),  
          }
        })
      }
      else{
        if(indexTax)
        {
          initialAmount = parseFloat(this.state.checkVoucher.amount) + parseFloat(this.state.checkVoucherContent[indexTax]['amount_credit'])
        }
        var items = this.state.checkVoucherContent
        items[index]["amount_credit"]=initialAmount
        this.setState({checkVoucherContent: items.filter(item => item.account_code !== this.state.withHoldingTax.account_code)})
        this.setState({
          checkVoucher:{
            ...this.state.checkVoucher,
            amount: initialAmount,
            taxable: false
          }
        })
      }
    }
  }
    } else if (event.target.id.includes("branch")) {
      if(values!==null){
        axios({
          method: 'get',
          url: '/v1/check_vouchers/search?branch_id='+values.id+'&sort_by[date_of_check]=desc',
          headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        }).then(() => {
          this.getReferenceNo()
        })
      }
      this.setState({ branch: values })
    }
  }



  populateContent(params, value, tax) {
    var contents = []
    var inputTax = tax
    if(!this.state.fromBills){

    if (this.state.checkVoucherContent.length <= 2 && this.state.withInputTax) {
      if (params.toLowerCase() === 'amount') {
        if (this.state.bank !== undefined) {
          contents = [
            {
              account_code: inputTax.code,
              account: inputTax,
              amount_debit: parseFloat(((value / 1.12 * .12) * 100) / 100).toFixed(2),
              amount_credit: 0
            },
            {
              account_code: this.state.contentBank.code,
              account: this.state.contentBank,
              amount_debit: 0,
              amount_credit: value
            },
          ]
        }
      } else if (params.toLowerCase() === 'bank') {
        if (this.state.checkVoucher.amount > 0) {
          contents = [
            {
              account_code: this.state.inputTax.code,
              account: this.state.inputTax,
              amount_debit: parseFloat((this.state.checkVoucher.amount / 1.12 * .12) * 100 / 100).toFixed(2),
              amount_credit: 0
            },
            {
              account_code: value.code,
              account: value,
              amount_debit: 0,
              amount_credit: this.state.checkVoucher.amount
            },
          ]

        }
      }
      this.setState({ checkVoucherContent: contents })
    }else{
      var index = this.state.checkVoucherContent.indexOf(this.state.checkVoucherContent.filter(item=>item.account_code===this.state.contentBank.code)[0])
      contents = this.state.checkVoucherContent
      var newContent = {}
      if (params.toLowerCase() === 'amount') {
        if (this.state.bank !== undefined) {
          if(index>-1){
            contents[index]["amount_credit"]=value

          }else{      
            newContent['account_code']= this.state.contentBank.code
            newContent['account']= this.state.contentBank
            newContent['amount_debit']= 0
            newContent['amount_credit']= value
            contents.push(newContent)  
          }

        }
      } else if (params.toLowerCase() === 'bank') {
        var bankIdx = 0
        if(this.state.withInputTax){
          bankIdx = 1
        }
        contents[bankIdx]["account_code"]=value.code
        contents[bankIdx]["account"]=value
        contents[bankIdx]["amount_debit"]=0
        contents[bankIdx]["amount_credit"]= this.state.checkVoucher.taxable? parseFloat(this.state.checkVoucher.amount-(this.state.checkVoucher.amount/1.12 * parseFloat(this.state.expenseType.multiplier))).toFixed(2):this.state.checkVoucher.amount
      }
      this.setState({ checkVoucherContent: contents })
    }
    
  }else{

    if (this.state.checkVoucherContent.length <= 1 ) {
      if (params.toLowerCase() === 'amount') {
        
        if (this.state.bank !== undefined) {
          contents = [
            ...this.state.checkVoucherContent,
            {
              account_code: this.state.contentBank.code,
              account: this.state.contentBank,
              amount_debit: 0,
              amount_credit: value
            },
          ]
        }
      } else if (params.toLowerCase() === 'bank') {
        if (this.state.checkVoucher.amount > 0) {
          contents = [
            ...this.state.checkVoucherContent,
            {
              account_code: value.code,
              account: value,
              amount_debit: 0,
              amount_credit: this.state.checkVoucher.amount
            },
          ]

        }
      }
      this.setState({ checkVoucherContent: contents })
    }else{
      if (params.toLowerCase() === 'amount') {
        if (this.state.bank !== undefined) {
          contents = [
            this.state.checkVoucherContent[0],
            {
              account_code: this.state.contentBank.code,
              account: this.state.contentBank,
              amount_debit: 0,
              amount_credit: value
            },
          ]
        }
      } else if (params.toLowerCase() === 'bank') {
        if (this.state.checkVoucher.amount > 0) {
          contents = [
            this.state.checkVoucherContent[0],
            {
              account_code: value.code,
              account: value,
              amount_debit: 0,
              amount_credit: this.state.checkVoucher.amount
            },
          ]

        }
      }
      this.setState({ checkVoucherContent: contents })
    }
    }

  }

  handleSubmit() {
    var checkVoucherContent = []
    var fordeleteCheckVoucherContent = this.state.fordeleteCheckVoucherContent
    if (this.state.checkVoucherContent.length > 0) {
      this.state.checkVoucherContent.map((content) => {
        var item = {}
        if(!content.id){
          item = {
            account_code: content.account.code,account_id: content.account.id,
            amount_credit: content.amount_credit,amount_debit: content.amount_debit
          }
        }else{
          item = {
            id: content.id,
            account_code: content.account.code,account_id: content.account.id,
            amount_credit: content.amount_credit,amount_debit: content.amount_debit
          }
        }
        checkVoucherContent.push(item)
      })
    }
    const item = this.state.checkVoucher
    item['bill_ids']=this.state.selectedBillIds
    var method = ''
    var url = ''
    if (item.id === '') {
      method = 'post'
      url = '/v1/check_vouchers/'
      
    } else {
      if (fordeleteCheckVoucherContent.length > 0) {
        fordeleteCheckVoucherContent.map((content) => {
          var item = {
            id: content.id,
            account_code: content.account.code,
            account_id: content.account.id,
            _destroy: true,
            amount_credit: 0.0,
            amount_debit: 0.0
          }
          checkVoucherContent.push(item)
        })
      }
   
      method = 'put'
      url = '/v1/check_vouchers/' + item.id
    }
    item["check_voucher_contents_attributes"] = checkVoucherContent
    item["bank_id"] = this.state.bank.id
    item["payee_id"] = this.state.payee.id
    item["expense_type_id"] = this.state.expenseType.id
    item["branch_id"] = this.state.branch.id
    item["reference_no"]=this.state.checkVoucher.reference_no
    item["postfix"] = localStorage.getItem('checkvoucher_prefix')?(localStorage.getItem('checkvoucher_prefix')):'CV'
    axios({
      method: method,
      url: url,
      data: (item),
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      const newData = resp.data
      this.setState({ checkVoucher: newData })
      this.setState({ redirect: true })
    }).catch(error => {
      if (error.response.status === 422) {
        var errors = {}
        Object.keys(error.response.data.errors[0].detail.errors).map((field)=>{
          errors[field] = error.response.data.errors[0].detail.errors[field][0]
         })
         this.setState({errorMessages: errors})
      } else {
        this.setState({isOpen: true,message: error.response.status.toString() + " Unexpected Error Occurred",type: 'error'})
      }
    })
  }

  handleSwitch(event) {
    var index = this.state.checkVoucherContent.indexOf(this.state.checkVoucherContent.filter(item=>item.account_code===this.state.contentBank.code)[0])
    const value = event.target.checked
    var inputTaxIndex = 0
    if(this.state.inputTax.code){
      inputTaxIndex = this.state.checkVoucherContent.indexOf(this.state.checkVoucherContent.filter(item=>item.account_code===this.state.inputTax.code)[0])
   }
    if(index!==-1){
    if (value === true) {
      const updatedCVContents = this.state.checkVoucherContent
      var amt = parseFloat(this.state.checkVoucher.amount-(this.state.checkVoucher.amount/1.12 * parseFloat(this.state.expenseType.multiplier))).toFixed(2)
      updatedCVContents[index]["amount_credit"]= parseFloat(this.state.checkVoucher.amount-(this.state.checkVoucher.amount/1.12 * parseFloat(this.state.expenseType.multiplier))).toFixed(2)
      var item=this.state.withHoldingTax
      item['amount_credit']=  parseFloat(this.state.checkVoucher.amount/1.12 * parseFloat(this.state.expenseType.multiplier)).toFixed(2)
      if(inputTaxIndex!==-1){
        updatedCVContents[inputTaxIndex]["amount_debit"]= parseFloat((amt / 1.12 * .12) * 100 / 100).toFixed(2)
      }
      this.setState({checkVoucherContent: [...updatedCVContents, item]})
      this.setState({checkVoucher: {...this.state.checkVoucher, 
        amount:amt,
        taxable: value
      }})
    } else {
      var amtToAdd = 0
      var inputAmt = 0
      var items = this.state.checkVoucherContent
      if (this.state.checkVoucherContent.length > 0) {
        this.state.checkVoucherContent.map((voucher) => {
          if (voucher.account_code === this.state.withHoldingTax.account_code) {
            amtToAdd = voucher.amount_credit
            if (voucher.id !== undefined) {
              this.setState({ fordeleteCheckVoucherContent: [...this.state.fordeleteCheckVoucherContent, voucher] })
            }
          }
        })
      }
      items[index]["amount_credit"]=parseFloat(parseFloat(this.state.checkVoucher.amount) + parseFloat(amtToAdd)).toFixed(2)
      inputAmt = parseFloat(parseFloat(this.state.checkVoucher.amount) + parseFloat(amtToAdd)).toFixed(2)
      if(inputTaxIndex!==-1){
        items[inputTaxIndex]["amount_debit"]= parseFloat((inputAmt / 1.12 * .12) * 100 / 100).toFixed(2)
      }
      this.setState({ forEditCheckVoucherContent: [...this.state.forEditCheckVoucherContent.filter(item => item.id ===items[index].id), items[index]] })
      this.setState({
        checkVoucher: {...this.state.checkVoucher, 
          amount: parseFloat(this.state.checkVoucher.amount) + parseFloat(amtToAdd),
          taxable: value
        }
      }) 
      this.setState({checkVoucherContent: items.filter(item => item.account_code !== this.state.withHoldingTax.account_code)})
    }  
  }else{
    this.setState({checkVoucher: {...this.state.checkVoucher, taxable: value}})
  }
  this.setState({billTaxable: !value})
  }

  addCheckVoucherContent() {
    this.setState({ checkVoucherContent: [...this.state.checkVoucherContent, { ...this.state.initialCheckVoucherContent }] })
  }

  handleCheckVoucherContentChange(e) {
    var value = null

    const updatedVoucher = [...this.state.checkVoucherContent]
    if (e.target.dataset.fieldType === "amount_debit" || e.target.dataset.fieldType === "amount_credit") {
      value = parseFloat(e.target.value).toFixed(2)
    } else {
      value = e.target.value
    }
    updatedVoucher[e.target.dataset.id][e.target.dataset.fieldType] = value
    if (updatedVoucher[e.target.dataset.id]["id"] !== undefined) {
      this.setState({ forEditCheckVoucherContent: [...this.state.forEditCheckVoucherContent.filter(item => item.id !== updatedVoucher[e.target.dataset.id].id), updatedVoucher[e.target.dataset.id]] })
    }
    this.setState({ checkVoucherContent: updatedVoucher })
  }

  removeCheckVoucherContent(idx, content) {
    var index =this.state.checkVoucherContent.indexOf(this.state.checkVoucherContent.filter(item=>item.account_code===this.state.contentBank.code)[0])
    const updatedVouchers = [...this.state.checkVoucherContent]
    if(updatedVouchers[idx].account_code===this.state.withHoldingTax.account_code){
      
      this.setState({checkVoucher: {...this.state.checkVoucher,taxable:false}})
      updatedVouchers[index]['amount_credit']=parseFloat(this.state.checkVoucher.amount) + parseFloat(updatedVouchers[idx].amount_credit)
      this.setState({checkVoucher: {...this.state.checkVoucher,amount:parseFloat(this.state.checkVoucher.amount) + parseFloat(updatedVouchers[idx].amount_credit)} })
    }
    if(updatedVouchers[idx].account_code===this.state.inputTax.code){
      this.setState({withInputTax: false})
    }
    
    updatedVouchers.splice(idx, 1)
    if(content){
    if (content.id !== undefined) {
      var item = this.state.checkVoucherContent[idx]
      this.setState({ fordeleteCheckVoucherContent: [...this.state.fordeleteCheckVoucherContent, item] })
    }
  }
    this.setState({ checkVoucherContent: updatedVouchers })
  }

  handleContentAutocomplete(event, values) {
    var updatedVoucher = [...this.state.checkVoucherContent]
    var editVouchers = [...this.state.forEditCheckVoucherContent]
    if (values !== null) {

      updatedVoucher[event]["account"] = values
      updatedVoucher[event]["account_code"] = values.code

      if (updatedVoucher[event]["id"] !== undefined) {
        this.setState({
          forEditCheckVoucherContent: this.state.forEditCheckVoucherContent.filter(item => item.id !== item.id)
        })
        this.setState({ forEditCheckVoucherContent: [...editVouchers, updatedVoucher[event]] })
      } else {
        this.setState({ checkVoucherContent: updatedVoucher })
      }
    } else {
      updatedVoucher = [...this.state.checkVoucherContent]
      updatedVoucher[event]["account"] = {}
      updatedVoucher[event]["account_code"] = ''
      this.setState({ checkVoucherContent: updatedVoucher })
    }

  }
  handleCheckVoucherContentAmount(event,idx,params){
      var value = 0
      if(event.floatValue!==undefined){
        value = event.floatValue
        
      }
      const updatedVoucher = [...this.state.checkVoucherContent]
      updatedVoucher[idx][params] = value
      if (updatedVoucher[idx]["id"] !== undefined) {
        this.setState({ forEditCheckVoucherContent: [...this.state.forEditCheckVoucherContent.filter(item => item.id !== updatedVoucher[idx].id), updatedVoucher[idx]] })
      }
      this.setState({ checkVoucherContent: updatedVoucher })
  }
  backToIndex(){
    this.setState({
      checkVoucher: {
        ...this.state.checkVoucher,
        id: ""
      }
    })
    this.setState({redirect: true})
  }
  handleAutocompleteSearch(e,value){
      axios({
        method: 'get',
        url: '/v1/payees/search?name=*'+value+'*',
        headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
      }).then(resp => {
        this.setState({ payees: resp.data.payees })
      })
  }
  
  handleInputTax(event){
   
      const value = event.target.checked
      this.setState({withInputTax: value})
      if(!value){
      
      if (this.state.checkVoucherContent.length > 0) {
        this.state.checkVoucherContent.map((voucher) => {
          if (voucher.account_code === this.state.inputTax.code) {
            if (voucher.id !== undefined) {
              this.setState({ fordeleteCheckVoucherContent: [...this.state.fordeleteCheckVoucherContent, voucher] })
            }
          }
        })
      }
      this.setState({
        checkVoucherContent: this.state.checkVoucherContent.filter(item => item.account_code !== this.state.inputTax.code)
      })
      
    }else{
     this.setState( { checkVoucherContent:[{
        account_code: this.state.inputTax.code,
        account: this.state.inputTax,
        amount_debit: parseFloat(((parseFloat(this.state.checkVoucher.amount) / 1.12 * .12)  * 100) / 100).toFixed(2),
        amount_credit: 0
      },...this.state.checkVoucherContent]
    })
  }
  this.setState({billInputTax: !value})
}

  openSelectBills(){
    this.setState({ billContents: []})
    this.setState({checkVoucherContent: []})
    axios({
      method: 'get',
      url: '/v1/accounts/search?name=Accounts Payable',
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({accountsPayable: resp.data.accounts[0]})
    })

    if(this.state.payee.id){
      axios({
        method: 'get',
        url: '/v1/bills/search?payee_id=' + this.state.payee.id+'&with_input_vat='+ this.state.billInputTax.toString()+'&taxable='+ this.state.billTaxable.toString()+'&due_at_from='+this.state.due_at_from+'&due_at_to='+this.state.due_at_to+'&is_paid=false&sort_by[due_at]=desc',
        headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
      }).then(resp => {
        this.setState({bills: resp.data.bills.sort(
          (a, b) => new Date(a.due_at).getTime() - new Date(b.due_at).getTime()
        )})
        this.setState({openBillsPopUp: true})
      })
    }
  }

  groupArrayOfObjects(list, key) {
    return list.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
}

  
  closeBills(){
    this.setState({ billContents: []})
    var payableContents = []
    if(this.state.selectedBillIds.length>0){
      this.state.selectedBillIds.map((billId)=>{
        axios({
          method: 'get',
          url: '/v1/bills/'+billId+'/contents',
          headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        }).then(resp => {
        payableContents = [...resp.data.filter(acc =>acc.account.name.includes("Accounts Payable")),...payableContents]
        this.setState({ billContents: [...payableContents]})
        if(payableContents.length === this.state.selectedBillIds.length){
          this.populateBills(payableContents)
        }
      })
    })}else{
      this.setState({ openBillsPopUp: false})
    }    
  }

  populateBills(billContents){
    var accountPayables = []
    var payableContents = billContents
    if(this.state.selectedBills.length>0){
           var amt = 0
           this.state.selectedBills.map((bill)=>{
             amt = parseFloat(parseFloat(amt) + parseFloat(bill.amount)).toFixed(2)
           })
           this.setState({
            checkVoucher: {
              ...this.state.checkVoucher,
              amount: amt
            }
          })
         }else{
           this.setState({checkVoucherContent: []})
           this.setState({
             checkVoucher: {
               ...this.state.checkVoucher,
               amount: 0
             }
           })
         }
         if (this.state.bank !== undefined) {
          var index = this.state.checkVoucherContent.indexOf(this.state.checkVoucherContent.filter(item=>item.account_code===this.state.contentBank.code)[0])
          var item=this.state.withHoldingTax
          item['amount_credit']=  parseFloat(amt/1.12 * parseFloat(this.state.expenseType.multiplier)).toFixed(2)
          var inputTax = {
            account_code: this.state.inputTax.code,
            account: this.state.inputTax,
            amount_debit: parseFloat(((amt / 1.12 * .12) * 100) / 100).toFixed(2),
            amount_credit: 0
          }
          var contents = [
            {
              account_code: this.state.contentBank.code,
              account: this.state.contentBank,
              amount_debit: 0,
              amount_credit: this.state.checkVoucher.taxable?parseFloat(amt-(amt/1.12 * parseFloat(this.state.expenseType.multiplier))).toFixed(2): parseFloat(amt).toFixed(2)
            }
          ]
        }
        if(this.state.checkVoucher.taxable && index!==-1){ 
        this.removeCheckVoucherContent(index,this.state.checkVoucherContent.filter(item=>item.account_code===this.state.withHoldingTax.account_code)[0])
      }
    if(billContents.length>0){
      Object.keys(this.groupArrayOfObjects(payableContents, "account_id")).map((item) => {
       var account = this.state.accounts.filter(acc=>acc.id.toString()===item)[0]
      var accountPayable = {}
      var sum = this.groupArrayOfObjects(payableContents, "account_id")[item].reduce((partial_sum, a) => parseFloat(parseFloat(partial_sum) + parseFloat(a.amount_credit)).toFixed(2),0)
        accountPayable = {
          account_code: account.code,
          account: account,
          amount_credit: 0,
          amount_debit: sum
        }
        accountPayables.push(accountPayable)
        if (this.state.checkVoucher.taxable && this.state.withInputTax) {
          this.setState({checkVoucherContent: [inputTax, ...contents,item,...accountPayables],openBillsPopUp: false})
        }else if (!this.state.checkVoucher.taxable && this.state.withInputTax) {
          this.setState({checkVoucherContent: [inputTax, ...contents,...accountPayables],openBillsPopUp: false})
        }else if (this.state.checkVoucher.taxable && !this.state.withInputTax) {
          this.setState({checkVoucherContent: [ ...contents,item,...accountPayables],openBillsPopUp: false})
        }else{
          this.setState({checkVoucherContent: [ ...contents,...accountPayables],openBillsPopUp: false})
        }
      })
    }
  }

  
  selectAllBills(){
    var checkAll = []
    var unCheckAll = this.state.selectedBillIds
    var checkRemaining = this.state.selectedBillIds
    if (this.state.bills.length > 0) {
      if (this.state.selectedBillIds.length <= 0) {
        this.state.bills.map((trans) => {
          checkAll.push(trans.id)
        })
         this.setState({ selectedBillIds: checkAll })
         this.setState({ selectedBills: this.state.bills})
      } else if (this.state.selectedBillIds.length === this.state.bills.length) {
        this.state.bills.map((trans) => {
          unCheckAll.splice(unCheckAll.indexOf(trans.id), 1)
        })
        this.setState({ selectedBillIds: unCheckAll })
        this.setState({ selectedBills: []})
      } else {
        this.state.bills.map((transMenu) => {
          var addState = true
          this.state.selectedBillIds.map((selectedId) => {
            if (selectedId === transMenu.id) {
              addState = false
            }
          })
          if (addState === true) {
            checkRemaining.push(transMenu.id)
            this.setState({ selectedBills: [...this.state.selectedBills, transMenu]})
          }
        })
        return this.setState({ selectedCases: checkRemaining })
      }
    }
  }

  handleCheckBills(e,item){
    
      var selectedExisting = this.state.selectedBillIds
      if (e.target.checked === true) {
        this.setState({ selectedBills: [...this.state.selectedBills, { ...item }] })
        selectedExisting.push(item.id)
      }
      else {
        this.setState({
          selectedBills: this.state.selectedBills.filter(bill => bill.id !== item.id)
        })
        selectedExisting.splice(selectedExisting.indexOf(item.id), 1)
      }
      this.setState({ selectedBillIds: selectedExisting})
    
  }

  handleBillInputTax(e){
      axios({
        method: 'get',
        url: '/v1/bills/search?payee_id=' + this.state.payee.id+'&with_input_vat='+ e.target.checked.toString()+'&taxable='+ this.state.billTaxable.toString()+'&due_at_from='+ this.state.due_at_from+'&due_at_to='+ this.state.due_at_to+'&is_paid=false',
        headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
      }).then(resp => {
        this.setState({bills: resp.data.bills.sort(
          (a, b) => new Date(a.due_at).getTime() - new Date(b.due_at).getTime()
        )})
      })
      this.setState({billInputTax: e.target.checked})
  }

  handleBillSwitch (e){
      axios({
        method: 'get',
        url: '/v1/bills/search?payee_id=' + this.state.payee.id+'&with_input_vat='+ this.state.billInputTax.toString()+'&taxable='+ e.target.checked.toString()+'&due_at_from='+ this.state.due_at_from+'&due_at_to='+ this.state.due_at_to+'&is_paid=false',
        headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
      }).then(resp => {
        this.setState({bills: resp.data.bills.sort(
          (a, b) => new Date(a.due_at).getTime() - new Date(b.due_at).getTime()
        )})
      })
      this.setState({billTaxable: e.target.checked})
    
  }

  handleDueDate(e){
    this.setState({[e.target.name]: e.target.value})
  }

  filter(){
    axios({
      method: 'get',
      url: '/v1/bills/search?payee_id=' + this.state.payee.id+'&with_input_vat='+ this.state.billInputTax.toString()+'&taxable='+ this.state.billTaxable.toString()+'&due_at_from='+ this.state.due_at_from+'&due_at_to='+ this.state.due_at_to+'&is_paid=false',
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({bills: resp.data.bills})
    })
  }

  getReferenceNo(){
    if(this.state.branch.id){
      axios.get('/v1/check_vouchers/generate_ref_no', {
        params: { branch_id: this.state.branch.id},
        headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
      }).then(resp => {
        if(resp.data.error===false){
          this.setState({checkVoucher: {...this.state.checkVoucher,
            prefix: `${localStorage.getItem('checkvoucher_prefix')?(localStorage.getItem('checkvoucher_prefix')):'CH'}${this.state.branch.name.charAt(0).toUpperCase()}`,
            reference_no: this.padFix(resp.data.reference_no)[0]}})}
          this.setState({errorMessages: {}})
      })
    }
    
  }
  
  padFix(n) {
    return ('00000000'+n.toString()).match(/\S{8}$/);
  }

  removeBill(billToRemove){
    var bill= billToRemove
    this.setState({selectedBillIds: this.state.selectedBillIds.filter(item=> item !==bill.id)})
    this.setState({selectedBills: this.state.selectedBills.filter(item=> item.id !==bill.id)})
    if(this.state.selectedBills.filter(item=> item.id !==bill.id).length>0){
      var amt = 0
      this.state.selectedBills.filter(item=> item.id !==bill.id).map((bill)=>{
        amt = parseFloat(parseFloat(amt) + parseFloat(bill.amount)).toFixed(2)
      })
    }else{
      this.setState({checkVoucherContent: []})
      this.setState({
        checkVoucher: {
          ...this.state.checkVoucher,
          amount: 0
        }
      })
    }
    if(amt!==undefined){
      this.setState({checkVoucherContent: []})
      
        var index = this.state.checkVoucherContent.indexOf(this.state.checkVoucherContent.filter(item=>item.account_code===this.state.contentBank.code)[0])
          this.setState({
            checkVoucher: {
              ...this.state.checkVoucher,
              amount: this.state.checkVoucher.taxable?parseFloat(amt-(amt/1.12 * parseFloat(this.state.expenseType.multiplier))).toFixed(2): amt
            }
          })
          if (this.state.bank !== undefined) {
            var item=this.state.withHoldingTax
            item['amount_credit']=  parseFloat(amt/1.12 * parseFloat(this.state.expenseType.multiplier)).toFixed(2)
            var inputTax = {
              account_code: this.state.inputTax.code,
              account: this.state.inputTax,
              amount_debit: parseFloat(((amt / 1.12 * .12) * 100) / 100).toFixed(2),
              amount_credit: 0
            }
            var contents = [
              {
                account_code: this.state.contentBank.code,
                account: this.state.contentBank,
                amount_debit: 0,
                amount_credit: this.state.checkVoucher.taxable?parseFloat(amt-(amt/1.12 * parseFloat(this.state.expenseType.multiplier))).toFixed(2): parseFloat(amt).toFixed(2)
              },
              {
                account_code: this.state.accountsPayable.code,
                account: this.state.accountsPayable,
                amount_debit: this.state.checkVoucher.taxable?parseFloat(amt-(amt/1.12 * parseFloat(this.state.expenseType.multiplier))).toFixed(2): parseFloat(amt).toFixed(2),
                amount_credit: 0
              }
            ]
            if (this.state.checkVoucher.taxable && this.state.withInputTax) {
              this.setState({checkVoucherContent: [inputTax, ...contents,item]})
            }else if (!this.state.checkVoucher.taxable && this.state.withInputTax) {
              this.setState({checkVoucherContent: [inputTax, ...contents]})
            }else if (this.state.checkVoucher.taxable && !this.state.withInputTax) {
              this.setState({checkVoucherContent: [ ...contents,item]})
            }else{
              this.setState({checkVoucherContent: [ ...contents]})
            }
          }
          if(this.state.checkVoucher.taxable && index!==-1){ 
          this.removeCheckVoucherContent(index,this.state.checkVoucherContent.filter(item=>item.account_code===this.state.withHoldingTax.account_code)[0])
        }
      this.setState({openBillsPopUp: false})
    }
    this.setState({openBillsPopUp: false})
  }

  render() {
    const { redirect,fromPayeeShow } = this.state
    if (redirect)
     { if(fromPayeeShow){
        return (<Redirect to={{
          pathname: '/payees/' + this.state.payee.id 
        }} />)

      }
      else{
        return (<Redirect to={{
          pathname: '/check_vouchers/' + this.state.checkVoucher.id,
          state: {
            id: this.state.checkVoucher.id,
            item: this.state.checkVoucher
          }
        }} />)
      }}
      
    return (

      this.state.load ? (
        <>
        <CheckVoucherForm
          error={this.state.errorMessages}
          handleSubmit={this.handleSubmit}
          handleAutocomplete={this.handleAutocomplete}
          handleInputChange={this.handleInputChange}
          handleCheckVoucherContentChange={this.handleCheckVoucherContentChange}
          removeCheckVoucherContent={this.removeCheckVoucherContent}
          addCheckVoucherContent={this.addCheckVoucherContent}
          handleSwitch={this.handleSwitch}
          amountChange={this.amountChange}
          accounts={this.state.accounts}
          branches={this.state.branches}
          payee={this.state.payee}
          branch={this.state.branch}
          bank={this.state.bank}
          expenseType={this.state.expenseType}
          checkVoucher={this.state.checkVoucher}
          checkVoucherContent={this.state.checkVoucherContent}
          payees={this.state.payees}
          banks={this.state.banks}
          expenseTypes={this.state.expenseTypes}
          backToIndex={this.backToIndex}
          handleContentAutocomplete={this.handleContentAutocomplete}
          handleCheckVoucherContentAmount={this.handleCheckVoucherContentAmount}
          handleAutocompleteSearch={this.handleAutocompleteSearch}
          withInputTax={this.state.withInputTax}
          handleInputTax={this.handleInputTax}
          contentBank={this.state.contentBank}
          selectedBills={this.state.selectedBills}
          openSelectBills={this.openSelectBills}
          setAmt={this.setAmt}
          fromBills={this.state.fromBills}
          getReferenceNo={this.getReferenceNo}
          removeBill={this.removeBill}
          fromPayeeShow={this.state.fromPayeeShow}
        />
        <SimplePopUp
          openPopup={this.state.openBillsPopUp}
          title="Select Bills"
          handleClose={this.closeBills}
          maxWidth='lg' 
        >
          <>
          <Grid container spacing={3}>
           <Grid item xs={12} lg={3}>
            <TextField
                autoComplete="off"
                variant="outlined"
                type="date"
                size="small"
                name="due_at_from"
                fullWidth
                label="Start Date"
                onChange={this.handleDueDate}
                value={this.state.due_at_from}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid> 
            <Grid  item xs={12} lg={3}>
             <TextField
              autoComplete="off"
              variant="outlined"
              type="date"
              size="small"
              name="due_at_to"
              fullWidth
              label="End Date"
              onChange={this.handleDueDate}
              value={this.state.due_at_to}
              InputLabelProps={{
                shrink: true
              }}
            />
            </Grid>
             <Grid item xs={6} lg={2}>
                <FormControlLabel
                  control={<Switch
                    size="small"
                  disabled={this.state.checkVoucher.taxable}
                  checked={this.state.billTaxable}
                  onChange={this.handleBillSwitch}
                  name="taxable" />}
                  label="Tax Expandable"
                />
              </Grid>
              <Grid item xs={6} lg={2}>
                  <FormControlLabel
                    control={<Switch
                      size="small"
                      disabled={this.state.withInputTax}
                      checked={this.state.billInputTax}
                      onChange={this.handleBillInputTax}
                      name="withInputTax" />}
                    label="With Input VAT"
                  />
                </Grid> 
                <Grid item xs={12} lg={2}>
                 <div className={styles.actionButton}><Button onClick={this.filter} color="primary" variant="outlined">Filter</Button></div>
                </Grid> 
            </Grid>
          {
            this.state.bills.length>0?(
              <>
              <p style={{float:'right'}}>{!this.state.billTaxable?'Non Taxable':'Taxable'} and {!this.state.billInputTax?'Without Input VAT':'With Input VAT'} Bills</p>
              <SelectBills 
                bills={this.state.bills}
                selectAllBills={this.selectAllBills}
                handleCheckBills={this.handleCheckBills}
                selectedBills={this.state.selectedBillIds}
                closeBills={this.closeBills}
              />
              </>
            ):null
          }
          </>
        </SimplePopUp>
        </>
      ) :  <ProgressBar model={this.state.model}/>
    )
  }
}

export default CheckVoucherNewAndUpdate

CheckVoucherNewAndUpdate.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
      bill_id: PropTypes.string
    })
  }),
  location: PropTypes.object
}
