// eslint-disable-next-line
import React from 'react'

import PropTypes from 'prop-types'

import SimpleTable from '../../shared/SimpleTable/SimpleTable'
import SimplePopUp from '../../shared/SimplePopUp/SimplePopUp'
import SimpleDeletePopUp from '../../shared/SimpleDeletePopUp/SimpleDeletePopUp'
import PayeeForm from './PayeeForm'
import PayeesSearch from './PayeesSearch'
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline'

import axios from 'axios'
import { withRouter } from 'react-router-dom'
import AlertMessage from "../../shared/Notify/AlertMessage";
import ProgressBar from "../../shared/ProgressBar/ProgressBar";

export const Component = withRouter(() => {

})

class Payees extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      model: 'Payee',
      payees: [],
      openPopup: false,
      openDeletePopup: false,
      payee: {},
      initialPayee: { id: '', name: '', reference_no: '', address: '', classification: 'vatable', tin: '', business_style: '' },
      title: '',
      load: false,
      tableHead: ['reference_no','Name', 'address', 'classification', 'TIN'],
      withShow: false,
      withEdit: true,
      maxWidth: 'sm',
      errorMessages: {},
      notify: {},
      isOpen: false,
      message: '',
      type: '',
      searchPayee: { perPage: '20'},
      draftPayees: [],
      totalPages: 1,
      currentPage: 1,
      withPagination: true,
      totalResults: 0,
      withResultsLabel: false,
      errorsTest: {},
      perPage: '20',
      defaultPerPage: '20',
      searchUrl: '/v1/payees/search'
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleDeleteItem = this.handleDeleteItem.bind(this)
    this.handleCreateorUpdateItem = this.handleCreateorUpdateItem.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleOpenDeletePopup = this.handleOpenDeletePopup.bind(this)
    this.handleDeleteClose = this.handleDeleteClose.bind(this)
    this.handleCloseAlert = this.handleCloseAlert.bind(this)
    this.handleSearch = this.handleSearch.bind(this)
    this.handleSearchInputChange = this.handleSearchInputChange.bind(this)
    this.clearSearch = this.clearSearch.bind(this)
    this.handlePageChange = this.handlePageChange.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.padFix = this.padFix.bind(this)
    this.handlePerPageChange=this.handlePerPageChange.bind(this)
    this.getReferenceNo = this.getReferenceNo.bind(this)
  }

  componentDidMount() {
    var searchUrl = this.state.searchUrl + this.props.location.search
    var searchParams = {}
    var perPage = ''
    const params = new URLSearchParams(this.props.location.search)
    params.forEach(function(value, key) {
      if (key !== 'page') {
        searchParams[key] = value.replace(/\*/g,'')
      }
      if(key === 'per_page'){perPage=value}
    })
    if(perPage){this.setState({perPage: perPage})}
    this.setState({ searchPayee: searchParams })
    this.loadPayees(searchUrl)
  }

  loadPayees(url) {
    axios({
      method: 'get',
      url: url.includes('?') ? url+'&sort_by[name]=asc': url+'?sort_by[name]=asc',
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ payees: resp.data.payees })
      this.setState({ totalPages: resp.data.meta.total_pages, currentPage: resp.data.meta.current_page })
      this.setState({ totalResults: resp.data.meta.total_count})
      this.setState({ withResultsLabel: true})
      this.setState({ load: true })
    }).catch((error) => {
      if(error.response)
      {this.setState({isOpen: true,message: " Unexpected Error Occurred",type: 'error'})}
    })
  }

  getSearchParams() {
    var searchParams = []
    Object.entries(this.state.searchPayee).map(([key, value]) => {
      if (key!=='per_page' &&value != '' && value != undefined && value != null) {
        searchParams.push([key,  key!=='per_page' && key!== 'classification'&& key!== 'page'?'*'+value+'*':value ].join("="))
      }
    })
    searchParams.push('per_page='+this.state.perPage)
    return searchParams
  }


  handleSubmit() {
    const item = this.state.payee
    var method = ''
    var url = ''
    if (item.id === '' || item.id === undefined) {
      method = 'post'
      url = '/v1/payees/'
    } else {
      method = 'put'
      url = '/v1/payees/' + item.id
    }
    axios({
      method: method,
      url: url,
      data: (item),
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {

      const newData = resp.data
      this.setState({
        payees: this.state.payees.filter(payee => payee.id !== item.id)
      })
      this.setState({ payees: [...this.state.payees, newData] })
      this.setState({ openPopup: false })
      this.setState({
        isOpen: true,
        message: 'Submitted Successfully',
        type: 'success'
      })
    }).catch(error => {
      if (error.response.status === 422) {
        var errors = {}
        Object.keys(error.response.data.errors[0].detail.errors).map((field)=>{
          errors[field] = error.response.data.errors[0].detail.errors[field][0]
         })
         this.setState({errorMessages: errors})
      } else {
        this.setState({
          isOpen: true,
          message: error.response.status.toString() + " Unexpected Error Occurred",
          type: 'error'
        })
        this.setState({ openPopup: false })
      }
    })
  }

  handleSearch() {
    const { history } = this.props
    var searchParams = this.getSearchParams().join("&")
    const params = new URLSearchParams()
    params.append("/search", searchParams)
    history.push({ search: searchParams })
    this.loadPayees([this.state.searchUrl, searchParams].join("?"), false)
  }

  handleSearchInputChange(e) {
    this.setState({
      searchPayee: {
        ...this.state.searchPayee,
        [e.target.name]: e.target.value
      }
    })
  }

  handleDeleteItem() {
    const DeleteItemId = this.state.payee.id
    axios({
      method: 'delete',
      url: '/v1/payees/' + DeleteItemId,
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(() => {
      this.setState({
        payees: this.state.payees.filter(item => item.id !== DeleteItemId)
      })
      this.setState({ openDeletePopup: false })
      this.setState({
        isOpen: true,
        message: 'Deleted Successfully',
        type: 'error'
      })
    }).catch((error) => {
      if(error.response)
      {
       var message =  " Unexpected Error Occurred"
        if(error.response.status.toString()==='422'){
          message = error.response.data.errors.base[0].replace('check', '').replace('cash', '')
        }
        this.setState({
          isOpen: true,
          message: message,
          type: 'error',
          openDeletePopup: false
        })
      }
    })
  }

  handleClose() {
    this.setState({ openPopup: false })
    this.setState({ openDeletePopup: false })
    this.setState({ errorMessages: {} })
  }

  handleCreateorUpdateItem(item, isAdd, model) {
    var textTitle = ''
    if (isAdd) {
      textTitle = 'Create ' + model
      this.getReferenceNo()
    } else {
      textTitle = 'Edit ' + model
    }
    this.setState({ payee: item })
    this.setState({ openPopup: true })
    this.setState({ title: textTitle })
  }

  handleInputChange(e) {
    if(e.target.name==="reference_no"){
      this.setState({
        payee: {
          ...this.state.payee,
          reference_no: this.padFix(e.target.value)[0]
        }
      })
    }else {
      this.setState({
        payee: {
          ...this.state.payee,
          [e.target.name]: e.target.value
        }
      })
    }
  }

  padFix(n) {
    return ('00000000'+n.toString()).match(/\S{8}$/);
  }

  handleOpenDeletePopup(item, model) {
    this.setState({ model: model })
    this.setState({ payee: item })
    this.setState({ openDeletePopup: true })
  }

  handleDeleteClose() {
    this.setState({ openDeletePopup: false })
  }

  handleCloseAlert(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ isOpen: false })
  }

  handleKeyPress(e) {
    if (e.key === 'Enter') {
      this.handleSearch()
    }
  }

  clearSearch() {
    const { history } = this.props
    const params = new URLSearchParams()
    params.append("/search", 'per_page='+this.state.perPage)
    history.push({ search: 'per_page='+this.state.perPage })
    this.loadPayees('/v1/payees/search?per_page='+this.state.perPage)
    this.setState({ searchPayee: { name: '', address: '', classification: '', tin: '' } })
  }

  handlePageChange(event, value) {
    var searchParams = this.getSearchParams()
    searchParams.push(["page", value].join("="))
    searchParams = searchParams.join("&")
    const { history } = this.props
    const params = new URLSearchParams()
    params.append("/search", searchParams)
    history.push({ search: searchParams })
    this.setState({ currentPage: value })
    this.loadPayees([this.state.searchUrl, searchParams].join("?"))
  }

  handlePerPageChange(e){
    var searchParams = this.getSearchParams()
    searchParams.map((value, index) => {
      if(value.includes("per_page")) {
        searchParams.splice(index, 1)
      }
    })
    searchParams.push(["per_page", e.target.value].join("="))
    searchParams = searchParams.join("&")
    const { history } = this.props
    const params = new URLSearchParams()
    params.append("/search", searchParams)
    history.push({ search: searchParams })
    this.setState({ perPage: e.target.value })
    this.loadPayees([this.state.searchUrl, searchParams].join("?"))
  }

  getReferenceNo(){
    axios({
      method: 'get',
      url:  '/v1/payees/generate_ref_no',
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ payee: { ...this.state.payee, reference_no: this.padFix(resp.data.reference_no)[0] }, errorMessages: {...this.state.errorMessages,reference_no: ''} })
    }).catch((error) => {
    if(error.response)
    {this.setState({isOpen: true,message: " Unexpected Error Occurred",type: 'error'})}
  })
  }

  render() {
    return (
      <>
        {
          this.state.load ? (
            <div>
              <PayeesSearch
                clearSearch={this.clearSearch}
                item={this.state.searchPayee}
                search={this.handleSearch}
                handleChange={this.handleSearchInputChange}
                handleKeyPress={this.handleKeyPress}
              />
              <SimpleTable
                handleClick={this.handleCreateorUpdateItem}
                initialItem={this.state.initialPayee}
                items={this.state.payees}
                model={this.state.model}
                onOpenDeletePopup={this.handleOpenDeletePopup}
                headers={this.state.tableHead}
                withShow={this.state.withShow}
                withEdit={this.state.withEdit}
                icon={<PeopleOutlineIcon fontSize="large" />}
                currentPage={this.state.currentPage}
                totalPages={this.state.totalPages}
                handlePageChange={this.handlePageChange}
                withPagination={this.state.withPagination}
                totalResults={this.state.totalResults}
                withResultsLabel={this.state.withResultsLabel}
                handlePerPageChange={this.handlePerPageChange}
                perPage={this.state.perPage}
              />
              <SimplePopUp
                openPopup={this.state.openPopup}
                title={this.state.title}
                handleClose={this.handleClose}
                maxWidth={this.state.maxWidth}
              >
                <PayeeForm error={this.state.errorMessages} item={this.state.payee} submit={this.handleSubmit} onchange={this.handleInputChange} getReferenceNo={this.getReferenceNo}/>
              </SimplePopUp>

              <SimpleDeletePopUp
                openDeletePopup={this.state.openDeletePopup}
                item={this.state.payee}
                delete={this.handleDeleteItem}
                handleDeleteClose={this.handleClose}
                model={this.state.model}
              />
              <AlertMessage
                notify={this.state.notify}
                handleCloseAlert={this.handleCloseAlert}
                isOpen={this.state.isOpen}
                type={this.state.type}
                message={this.state.message}
              />
            </div>
          ) : (
            <ProgressBar model={this.state.model}/>
          )}
      </>
    )
  }
}

export default withRouter(Payees)

Payees.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object
}
