import React from 'react'
import PropTypes from 'prop-types'
import styles from './SimpleTable.module.css'
import CompanyHeader from '../CompanyHeader/CompanyHeader'
import {Redirect} from 'react-router'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton'
import { MenuItem } from '@material-ui/core'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import VisibilityIcon from '@material-ui/icons/Visibility'
import TableContainer from "@material-ui/core/TableContainer"
import {Button, TextField, Tooltip} from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search'
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore'
import Pagination from '@material-ui/lab/Pagination';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import moment from 'moment'

class SimpleTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      redirect: false,
      pathItem: {}
    }
  }

  getTotalDebit(item) {
    var totalDebit = 0
    if (item.journal_entries.length > 0) {
      item.journal_entries.map((content) => {
        totalDebit = parseFloat(totalDebit) + parseFloat(content.amount_debit)
      })
    }
    return totalDebit
  }

  getTotalCredit(item) {
    var totalCredit = 0
    if (item.journal_entries.length > 0) {
      item.journal_entries.map((content) => {
        totalCredit = parseFloat(totalCredit) + parseFloat(content.amount_credit)
      })
    }
    return totalCredit
  }

  redirectTo(item) {
    this.setState({redirect: true})
    this.setState({pathItem: item})
  }

  handleChildClick(e) {
    e.stopPropagation();
  }

  render() {
    const pathName = '/' + this.props.model.replace(' ', '_').toLowerCase() + 's/'
    if (this.state.redirect) {
      if (this.state.pathItem !== null) {
        if(this.props.checkVoucherPayee && this.props.model.toLowerCase() === 'check voucher'){
          return (<Redirect
            to={{pathname: '/payees/' + this.state.pathItem.payee_id+'/check_vouchers/' + this.state.pathItem.id,state: {id: this.state.pathItem.id,item: this.state.pathItem}}}
              />)
        }else{
        return (<Redirect
          to={this.props.model.toLowerCase() === 'check voucher' ||this.props.model.toLowerCase() === 'cash voucher' || this.props.model.toLowerCase() === 'payee' || this.props.model.toLowerCase() === 'journal' || this.props.model.toLowerCase() === 'bill' ? (
            {
              pathname: pathName + this.state.pathItem.id,
              state: {
                id: this.state.pathItem.id,
                item: this.state.pathItem
              }
            }) : ('#')
          }
        />)
        }
      }
    }
    const pagecount = ['10','20','30','40','50','60','70','80','90','100']
    return (
      <div id="table-to-xls" >
        <CompanyHeader forPrint={this.props.forPrint}/>
        <>{
          this.props.noHeader?(null):
          <>
          {
            this.props.withResultsLabel?(
          <div className={styles.perPage} >
            <div>Displaying</div>
            <TextField size="small" 
            variant="outlined" 
            value={this.props.perPage}
             select onChange={this.props.handlePerPageChange} 
             className={styles.perPageSelect}> 
             {
               pagecount.map((count,idx)=>(
                <MenuItem value={count} key={idx}>{count}</MenuItem>      
               ))
             }
            </TextField>
            <div>records</div>
          </div>
          ):null
        }
          <div className={styles.tableTitle}>
            <div className={styles.groupTileSearch}>
              <div className={styles.tileBody}>
                {this.props.icon}
                <h2 className={styles.title}>
                  <div className={styles.head}>
                    <div>{this.props.model.replace('_', ' ') + 's'}</div>
                  </div>
                </h2>
              </div>
              {this.props.withSearch ? (
                <div>
                  <div className={styles.searchBody}>
                    <div className={styles.searchList}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label={<div className={styles.searchBar}><SearchIcon/>
                          <div>Search</div>
                        </div>}
                        size="small"
                        value={this.props.q}
                        className={styles.searchField}
                        onKeyPress={this.props._handleKeyPress}
                        onChange={this.props.handleChanges}
                        name="global_search"
                      >
                      </TextField>
                      {
                        this.props.model.toLowerCase() !== "document" ? (
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            className={styles.advancedSearch}
                            onClick={this.props.handleAdvanced}
                          >
                            Advanced Search
                          </Button>
                        ) : null
                      }

                      <IconButton
                        variant="contained"
                        color="secondary"
                        size="small"
                        onClick={this.props.clearSearch}
                      >
                        <SettingsBackupRestoreIcon/>
                      </IconButton>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            <div className={styles.rightAction}>
              {
                this.props.withPagination ? (
                  this.props.totalPages !== 1 ? (
                    <Pagination
                      className={styles.paginationWidth}
                      size="small"
                      count={this.props.totalPages}
                      page={this.props.currentPage}
                      color="primary"
                      onChange={this.props.handlePageChange}
                      showFirstButton
                      showLastButton
                    />
                  ) : null
                ) : null
              }

              {this.props.withPrint ? (
                <div className={styles.buttonDisbursement}>
                      {this.props.urlPrint.reports_url !== undefined ? (
                        <Tooltip title={`Print ${this.props.model}`} arrow>
                          <div className={this.props.forPrint ? styles.noDisplay : null}>
                            {this.props.printPdf}
                          </div>
                        </Tooltip>
                      ) : null}
                  {this.props.urlPrint.export_excel_url !== undefined ? (
                    <Tooltip title="Download Excel File" arrow className={this.props.forPrint? styles.noDisplay:null}>
                      <a href={`${this.props.urlPrint.export_excel_url}`} target="_blank"
                         rel="noopener noreferrer">
                        <CloudDownloadIcon/>
                      </a>
                    </Tooltip>
                  ) : null}
                </div>
              ) : null}
              {
                this.props.withXlsxDownload && this.props.isSearchStartFromSet
                && !this.props.forPrint && this.props.items.length ?
                <Tooltip 
                  title="Download to .xlsx">
                  <IconButton
                    color="primary" 
                    aria-label='download'
                    style={{ pointerEvents: 'auto' }}
                    onClick={this.props.handleXlsxDownloadClick}
                  >
                    <CloudDownloadIcon fontSize='small'/>
                  </IconButton>
                </Tooltip> :
                null
              }
              {

        this.props.model !== "Journal Register" && this.props.model.toLowerCase() !== 'disbursement register' && this.props.model.toLowerCase() !== 'journal register' && this.props.model.toLowerCase() !== 'general ledger' ? (
                    <div>
                      <IconButton color="inherit" aria-label="menu"
                                  id={`${this.props.model.toLowerCase().replace(/ /g, '_')}_add`}
                                  name={`${this.props.model.toLowerCase().replace(/ /g, '_')}_add`}
                                  onClick={() => this.props.handleClick(this.props.initialItem, true, this.props.model)}>
                        <AddCircleOutlineIcon fontSize="large"/>
                      </IconButton>
                    </div>
                ) : null
              }
            </div>
          </div>
          <div className={styles.resultsLabel}>
            {
              this.props.withResultsLabel ? (
                this.props.totalResults ? (
                    this.props.totalResults > 1 ?
                    ('Displaying '+((parseInt(this.props.currentPage)*parseInt(this.props.perPage))-(parseInt(this.props.perPage)-1)).toString()+'-'+((parseInt(this.props.currentPage)*parseInt(this.props.perPage))-(parseInt(this.props.perPage)-1) +(this.props.items.length-1) ).toString() +' of '+this.props.totalResults + ' in total') :
                      (this.props.totalResults + ' result')
                  ) :
                  null
              ) : null
            }
          </div>
            </>
          }
          <TableContainer className={this.props.forPrint? styles.tableBodyHeight:this.props.customClassName} style={{maxHeight: '575px', position: 'relative'}}>
            <Table size="small" stickyHeader aria-label="customized sticky table"
                   name={this.props.model.toLowerCase().replace(/ /g, '_') + "_table"}>
              <TableHead className={styles.tableHeadBody}>
                <TableRow>
                  {
                    this.props.headers.length > 0 ? (
                      this.props.headers.map((header) => (
                        header.toLowerCase() === 'actions' ? (
                          <TableCell key={header} className={styles.actions}
                                     align="center"><strong>{header}</strong></TableCell>
                        ) : ( header.toLowerCase() === 'is_posted' ? (
                            <TableCell key={header} className={styles.actions}
                                       align="center"><strong>Status</strong></TableCell>
                          ) : (header.toLowerCase() === 'amount' ? (
                              <TableCell key={header} className={styles.actions}
                                         align="right"><strong>Amount</strong></TableCell>) : (
                              header.includes('.') ? (
                                  header === 'bank.name' ? (
                                    <TableCell key={header}
                                               className={styles.accounName}><strong>Bank</strong></TableCell>
                                  ) : (
                                    header === 'payee.name' ? (
                                      <TableCell key={header}
                                                 className={styles.accounName}><strong>Payee</strong></TableCell>
                                    ) : (
                                      header.toLowerCase() === 'account.name' ? (
                                        <TableCell key={header}
                                                   className={styles.cell}><strong>Account Name</strong></TableCell>
                                      ) : (
                                        header.toLowerCase() === 'branch.name' ? (
                                          <TableCell key={header}
                                                     className={styles.cell}><strong>Branch</strong></TableCell>
                                        ) : (
                                          <TableCell key={header}
                                                     className={styles.cell}><strong>{header.split('.')[1].replace('_', ' ').replace('.', ' ')}</strong></TableCell>
                                        )
                                      )
                                    )
                                  )
                                )
                                : (header === 'prefix' ? (
                                    <TableCell key={header}
                                               className={styles.cell}><strong>Voucher No.</strong>
                                    </TableCell>
                                  ) : (
                                    header === 'is_paid' ? (
                                      <TableCell key={header}
                                                 className={styles.cell}><strong>Paid Status</strong>
                                      </TableCell>
                                    ) : (
                                    header === 'remarks' ? (
                                      <TableCell key={header}
                                                 className={styles.cell}><strong>Particulars</strong>
                                      </TableCell>
                                    ) : (
                                      header === 'journal_entries' ? (
                                        <TableCell key={header} className={styles.customHeader}>
                                          <TableCell className={styles.accounCode}>
                                            Account Number
                                          </TableCell>
                                          <TableCell className={styles.accounName}>
                                            Account Code
                                          </TableCell>
                                          <TableCell className={styles.debit}>
                                            Debit
                                          </TableCell>
                                          <TableCell className={styles.credit}>
                                            Credit
                                          </TableCell>
                                        </TableCell>
                                      ) : (
                                        header === "name" && this.props.model === 'Balance Sheet' ? (
                                          <TableCell key={header}
                                                     className={styles.cell}><b>Account Name</b>
                                          </TableCell>
                                        ) : (
                                          header === "Employee name" ? (
                                            <TableCell key={header}
                                                       className={styles.cell}><b>User</b>
                                            </TableCell>
                                          ) : (
                                            header === "logged_at" ? (
                                              <TableCell key={header}
                                                         className={styles.cell} align="center"><b>Date Log</b>
                                              </TableCell>
                                            ) : (
                                            <TableCell key={header}
                                                       className={styles.cell}><b>{header.replace('_', ' ').replace('_', ' ')}</b>
                                            </TableCell>
                                          )
                                        )
                                      )
                                      )
                                    )
                                  )
                                )
                                )
                            )
                          )
                        ))
                      )
                    ) : (
                      null
                    )
                  }
                </TableRow>
              </TableHead>
              <TableBody className={styles.tableBody}>
                {
                  this.props.items.length>0?
                  this.props.items.map((item, idx) => (
                      <TableRow key={idx} onClick={this.props.model.toLowerCase() === 'check voucher' ||this.props.model.toLowerCase() === 'cash voucher' || this.props.model.toLowerCase() === 'payee' || this.props.model.toLowerCase() === 'journal' || this.props.model.toLowerCase() === 'bill' ? () => this.redirectTo(item) : null  }
                                className={this.props.model.toLowerCase() === 'journal' || this.props.model.toLowerCase() === 'check voucher' || this.props.model.toLowerCase() === 'cash voucher' || this.props.model.toLowerCase() === 'payee' || this.props.model.toLowerCase() === 'bill'|| this.props.model.toLowerCase() === 'journal register'|| this.props.model.toLowerCase() === 'disbursement register' ? styles.linkable : styles.notLinkable}>
                        {
                          this.props.headers.length > 0 ? (
                            this.props.headers.map((header, idx) => (
                                header.includes(".") ? (
                                  header === "legal_case.client_uids" ? (
                                      <TableCell key={header + idx.toString}
                                                 className={styles.cell}>
                                        {this.getClientNames(item.legal_case)}
                                      </TableCell>) :
                                    (
                                      <TableCell key={header} className={styles.cell}>
                                        <>{item[header.split(".")[0]] ? (item[header.split(".")[0]][header.split(".")[1]]) : ("n/a")}</>
                                      </TableCell>
                                    )
                                ) : (
                                  header.toLowerCase() === "amount" ? (
                                    <TableCell key={header} className={styles.amount}>
                                      {item[header.toLowerCase()] ? (parseFloat(item[header.toLowerCase()])).toLocaleString(navigator.language, {minimumFractionDigits: 2}) : ("n/a")}
                                    </TableCell>
                                  ) : (
                                    header.toLowerCase() === "date_of_check" || header.toLowerCase() === "date_of_journal" ? (
                                      <TableCell key={header} className={styles.cell}>
                                        {item[header.toLowerCase()] ? (new Date(item[header.toLowerCase()]).toLocaleDateString('en-Us', {
                                          month: '2-digit',
                                          day: '2-digit',
                                          year: '2-digit'
                                        })) : ("n/a")}
                                      </TableCell>
                                    ) : (
                                      header.toLowerCase() === "prefix" ? (
                                        <TableCell key={header} className={styles.cell}>
                                          {item.prefix ? (item.prefix) : (null)}{item.reference_no}
                                        </TableCell>
                                      ) : (
                                        header.toLowerCase() === "remarks" ? (
                                          <TableCell key={header} className={styles.remarksEllipsis}>
                                            {item[header.toLowerCase()] ? (item[header.toLowerCase()].replace('_', ' ')) : ("n/a")}
                                          </TableCell>
                                        ) : (
                                          header.toLowerCase() === "type" ? (
                                            item.type.includes("Asset") ? (
                                              <TableCell key={header} className={styles.remarksEllipsis}>
                                                Asset
                                              </TableCell>
                                            ) : (
                                              item.type.includes("Expense") ? (
                                              <TableCell key={header} className={styles.remarksEllipsis}>
                                                Expense
                                              </TableCell>
                                            ) : ( item.type.includes("Liability") ? (
                                              <TableCell key={header} className={styles.remarksEllipsis}>
                                                Liability
                                              </TableCell>
                                              ):
                                                (item.type.includes("Revenue") ? (
                                                  <TableCell key={header} className={styles.remarksEllipsis}>
                                                    Revenue
                                                  </TableCell>
                                                ):(item.type.includes("Capital") ? (
                                                  <TableCell key={header} className={styles.remarksEllipsis}>
                                                    Capital
                                                  </TableCell>
                                                ): null))))
                                          ) : (
                                            header.toLowerCase() === "reference_no" ?
                                              (
                                                this.props.model.includes("Disbursement") || this.props.model === "Journal Register" ? (
                                                    <TableCell key={header} className={styles.linkable}>
                                                      <a href={this.props.model === "Journal Register" || item.resource_url === null?`/journals/${item.id}`:`${item.resource_url}`} target="_blank"
                                                         rel="noopener noreferrer">
                                                        {this.props.model === "Journal Register"|| item.resource_url === null?'JV':null}{item.branch_name[0]}{item[header.toLowerCase()] ? (item[header.toLowerCase()].replace('_', ' ')) : ("n/a")}
                                                      </a>
                                                    </TableCell>)
                                                  : (
                                                    this.props.model === "Journal"  ? (
                                                      <TableCell key={header} className={styles.linkable}>
                                                          JV{item.branch_name[0]}{item[header.toLowerCase()] ? (item[header.toLowerCase()].replace('_', ' ')) : ("n/a")}
                                                      </TableCell>
                                                    ) : (
                                                      <TableCell key={header}
                                                                                  className={styles.refNum}>
                                                      {item[header.toLowerCase()] ? (item[header.toLowerCase()].toString().replace('_', ' ')) : ("n/a")}
                                                    </TableCell>)
                                                  )
                                              ) : (
                                                header === "journal_entries" ? (
                                                  <Table size="small" className={styles.customRowBody}>
                                                    {item.journal_entries.length > 0 ? (
                                                      <>
                                                        {item.journal_entries.sort(function (a, b) {
                                                          if (a.account&& b.account&& a.account.code.toLowerCase() < b.account.code.toLowerCase()) return -1;
                                                          if (a.account && b.account && a.account.code.toLowerCase() > b.account.code.toLowerCase()) return 1;
                                                          return 0;
                                                        }).map((entry) => (
                                                          entry.account &&entry.amount_credit === "0.0" &&
                                                          <TableRow key={entry.uid} className={styles.customRow}>
                                                            <TableCell className={styles.accounCode}>
                                                              {entry.account.code}
                                                            </TableCell>
                                                            <TableCell className={styles.accounName}>
                                                              {entry.account.name}
                                                            </TableCell>
                                                            <TableCell className={styles.debit}>
                                                              {parseFloat(entry.amount_debit).toLocaleString(navigator.language, {minimumFractionDigits: 2})}
                                                            </TableCell>
                                                            <TableCell className={styles.credit}>
                                                              {parseFloat(entry.amount_credit).toLocaleString(navigator.language, {minimumFractionDigits: 2})}
                                                            </TableCell>
                                                          </TableRow>
                                                        ))}
                                                        {item.journal_entries.sort(function (a, b) {
                                                          if (a.account.code.toLowerCase() < b.account.code.toLowerCase()) return -1;
                                                          if (a.account.code.toLowerCase() > b.account.code.toLowerCase()) return 1;
                                                          return 0;
                                                        }).map((entry) => (
                                                          entry.amount_credit !== "0.0" &&
                                                          <TableRow key={entry.uid} className={styles.customRow}>
                                                            <TableCell className={styles.accounCode}>
                                                              {entry.account.code}
                                                            </TableCell>
                                                            <TableCell className={styles.accounName}>
                                                              {entry.account.name}
                                                            </TableCell>
                                                            <TableCell className={styles.debit}>
                                                              {parseFloat(entry.amount_debit).toLocaleString(navigator.language, {minimumFractionDigits: 2})}
                                                            </TableCell>
                                                            <TableCell className={styles.credit}>
                                                              {parseFloat(entry.amount_credit).toLocaleString(navigator.language, {minimumFractionDigits: 2})}
                                                            </TableCell>
                                                          </TableRow>
                                                        ))}
                                                        <TableRow>
                                                          <TableCell>
                                                          </TableCell>
                                                          <TableCell align="right">
                                                            <b>Total</b>
                                                          </TableCell>
                                                          <TableCell align="right" className={styles.debit}>
                                                            <b>{this.getTotalDebit(item).toLocaleString(navigator.language, {minimumFractionDigits: 2})}</b>
                                                          </TableCell>
                                                          <TableCell align="right" className={styles.credit}>
                                                            <b>{this.getTotalCredit(item).toLocaleString(navigator.language, {minimumFractionDigits: 2})}</b>
                                                          </TableCell>
                                                        </TableRow>
                                                      </>
                                                    ) : null}
                                                  </Table>
                                                ) : (
                                                  header === "Employee name" ? (
                                                    item.employee_name.length > 0 ? (
                                                      <TableCell key={header} className={styles.employeeNameWidth}>
                                                        {item.employee_name.map((name) => (
                                                            <li key={name.id}> {name.name_formal}</li>
                                                          )
                                                        )}
                                                      </TableCell>
                                                    ):<TableCell></TableCell>

                                                  ) : (
                                                  header.toLowerCase() === "is_posted" ? (
                                                    <TableCell key={header} className={styles.cell}>
                                                      {item[header.toLowerCase()] ? ("Posted") : ("Not posted")}
                                                    </TableCell>

                                                  ) : (
                                                    header.toLowerCase() === "is_paid" ? (
                                                      <TableCell key={header} className={styles.cell}>
                                                        {item[header.toLowerCase()] ? ("Paid") : ("Not yet paid")}
                                                      </TableCell>

                                                    ) : (
                                                    header.toLowerCase() === "account_balance" || header.toLowerCase() === "balance" || header.toLowerCase() === "compared_balance" || header.toLowerCase() === "amount_balance" || header.toLowerCase() === "amount_credit" || header.toLowerCase() === "amount_debit" ? (
                                                      <TableCell key={header} className={styles.amount}>
                                                        {
                                                          item[header.toLowerCase()] ? (
                                                            item[header.toLowerCase()] > 0 ? (
                                                              parseFloat(item[header.toLowerCase()]).toLocaleString(navigator.language, {minimumFractionDigits: 2}
                                                              )) : (
                                                              "(" + parseFloat(item[header.toLowerCase()] * -1)
                                                                .toLocaleString(navigator.language, {minimumFractionDigits: 2}) + ")"
                                                            )
                                                          ) : ("0.00")
                                                        }
                                                      </TableCell>

                                                    ) : (
                                                      header.toLowerCase() === "logged_at" ? (
                                                        <TableCell key={header} className={styles.cell} align='center'>{moment(item[header.toLowerCase()]).format('lll')}</TableCell>
                                                      ) : (
                                                        <TableCell key={header}
                                                                   className={styles.cell}>
                                                          {item[header.toLowerCase()] ? (item[header.toLowerCase()].toString().replace('_', ' ')) : ("n/a")}
                                                        </TableCell>
                                                      )
                                                    )
                                                    )

                                                  )
                                                )
                                              )
                                            )
                                          )
                                        )
                                      )
                                    )
                                  )
                                )
                              )
                            )
                          ) : (
                            null
                          )
                        }
                        <TableCell onClick={this.handleChildClick} size="small" className={this.props.model.toLowerCase() !== 'expense type' && this.props.model !== 'Account' && this.props.model !== 'Bank' && this.props.model !== 'Permission'&& this.props.model.includes('branch') ? styles.actionButtonHoverable : styles.fullWitdActionHoverable}>
                          {
                            this.props.withShowPopUp ? (
                              <Tooltip title={`Show ${this.props.model? this.props.model: null}`} arrow>
                                <IconButton
                                  id={`${this.props.model.toLowerCase().replace(/ /g, '_')}_showpopup`}
                                  name={`${this.props.model.toLowerCase().replace(/ /g, '_')}_showpopup`}
                                  size="small"
                                  color="primary"
                                  onClick={() => this.props.handleShowPopUp(item)}>
                                  <VisibilityIcon color="primary" fontSize="small"/>
                                </IconButton>
                              </Tooltip>
                            ) : (null)
                          }
                          {item.can_delete === true ? (
                            !this.props.model.toLowerCase().includes("disbursement") &&this.props.model.toLowerCase() !== "cash voucher" &&this.props.model.toLowerCase() !== "check voucher"  && this.props.model.toLowerCase() !== "journal" && this.props.model.toLowerCase() !== "bill"? (
                              <Tooltip title="Delete" arrow>
                                <IconButton
                                  id={`${this.props.model.toLowerCase().replace(/ /g, '_')}_delete`}
                                  name={`${this.props.model.toLowerCase().replace(/ /g, '_')}_delete`}
                                  size="small"
                                  color="secondary"
                                  onClick={() => this.props.onOpenDeletePopup(item, this.props.model)}
                                >
                                  <DeleteIcon/>
                                </IconButton>
                              </Tooltip>
                            ) : null
                          ) : null}
                          {item.can_edit === true ? (
                            this.props.withEdit ? (
                              <Tooltip title="Edit" arrow>
                                <IconButton
                                  size="small"
                                  id={`${this.props.model.toLowerCase().replace(/ /g, '_')}_edit`}
                                  name={`${this.props.model.toLowerCase().replace(/ /g, '_')}_edit`}
                                  color="primary"
                                  onClick={() => this.props.handleClick(item, false, this.props.model)}>
                                  <EditIcon/>
                                </IconButton>
                              </Tooltip>
                            ) : (null))
                            : null}
                        </TableCell>
                      </TableRow>
                  ))
                  :
                    <TableRow>
                      <TableCell className={styles.cell} colSpan={this.props.headers.length} align="center"><b>No records found</b></TableCell>
                    </TableRow>
                }

              </TableBody>
            </Table>
          </TableContainer>
        </>
      </div>

    )
  }
}

export default SimpleTable

SimpleTable.propTypes = {
  model: PropTypes.string.isRequired,
  headers: PropTypes.array.isRequired,
  items: PropTypes.array.isRequired,
  journalEntries: PropTypes.array,
  onOpenDeletePopup: PropTypes.func,
  handleClick: PropTypes.func,
  initialItem: PropTypes.object,
  item: PropTypes.object,
  withShow: PropTypes.bool,
  icon: PropTypes.object,
  withEdit: PropTypes.bool,
  withShowPopUp: PropTypes.bool,
  withSearch: PropTypes.bool,
  customClassName: PropTypes.string,
  handleShowPopUp: PropTypes.func,
  handleAdvanced: PropTypes.func,
  handleChanges: PropTypes.func,
  _handleKeyPress: PropTypes.func,
  clearSearch: PropTypes.func,
  q: PropTypes.string,
  prevPath: PropTypes.string,
  withPagination: PropTypes.bool,
  handlePageClick: PropTypes.func,
  pageCount: PropTypes.number,
  displayedPages: PropTypes.number,
  displayedRange: PropTypes.number,
  totalPages: PropTypes.number,
  page: PropTypes.number,
  changeIndex: PropTypes.func,
  showState: PropTypes.bool,
  withStateButton: PropTypes.bool,
  withCalendar: PropTypes.bool,
  handlePageChange: PropTypes.func,
  toggleView: PropTypes.func,
  checkVoucherPayee: PropTypes.bool,
  withPrint: PropTypes.bool,
  urlPrint: PropTypes.string,
  currentPage: PropTypes.number,
  activePage: PropTypes.number,
  totalResults: PropTypes.number,
  withResultsLabel: PropTypes.bool,
  payeeId: PropTypes.number,
  income: PropTypes.object,
  noHeader: PropTypes.bool,
  handlePerPageChange: PropTypes.func,
  perPage: PropTypes.string,
  printPdf: PropTypes.object,
  forPrint: PropTypes.bool,
  xlsxDownload: PropTypes.object,
  withXlsxDownload: PropTypes.bool,
  handleXlsxDownloadClick: PropTypes.func,
  isSearchStartFromSet: PropTypes.bool
}
