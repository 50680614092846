// eslint-disable-next-line

import React from 'react'
import SimplePopUp from '../../shared/SimplePopUp/SimplePopUp'
import SimpleDeletePopUp from '../../shared/SimpleDeletePopUp/SimpleDeletePopUp'
import CheckVoucherContentDetailTab from './CheckVoucherTabs/CheckVoucherContentDetailTab'
import JournalEntriesDetailsTab from './CheckVoucherTabs/JournalEntriesDetailTab'
import AttachmentDetailTab from './CheckVoucherTabs/AttachmentDetailTab'
import {Redirect} from 'react-router'
import {Link} from 'react-router-dom'
import IconButton from '@material-ui/core/IconButton'
import axios from 'axios'
import AlertMessage from "../../shared/Notify/AlertMessage";
import ProgressBar from "../../shared/ProgressBar/ProgressBar";
import Grid from '@material-ui/core/Grid'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import styles from './CheckVoucher.module.css'
import Typography from '@material-ui/core/Typography'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'

import {Button, Tooltip, TextField} from '@material-ui/core'

import PropTypes from 'prop-types'
import converter from "number-to-words";
import AttachmentForm from "./Attachments/AttachmentForm";
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import moment from 'moment'
import Bills from './Bills'
import AuditLog from '../../shared/AuditLog/AuditLog'
import ReactToPrint from "react-to-print";
import CompanyHeader from '../../shared/CompanyHeader/CompanyHeader'

function TabPanel(props) {
  const {children, value, index, ...other} = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

class CheckVoucherShow extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      model: 'Check Voucher',
      value: 0,
      checkVouchers: [],
      checkVoucherContents: [],
      journalEntries: [],
      attachments: [],
      openPopup: false,
      openAttachmentPopup: false,
      cancelCheckVoucherPopup: false,
      activeCheckVoucherPopup: false,
      activeJournalPopup: false,
      cancelJournalPopup: false,
      openDeletePopup: false,
      redirectToIndex: false,
      checkVoucher: {},
      initialCheckVoucher: {id: '', name: ''},
      attachment: {title: '', file: '', description: ''},
      title: '',
      load: false,
      tableHead: ['Name', 'Actions'],
      withShow: false,
      withEdit: true,
      maxWidth: 'sm',
      error_messages: '',
      isOpen: false,
      message: '',
      type: '',withHoldingTaxAmt: 0,
      reverseJournalPopUp: false,
      forPrint: false,
      signatory: {},
      openPrintPopup: false,
      changeSignPopup: false,
      openPrintPDFPopup: false,
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleDeleteItem = this.handleDeleteItem.bind(this)
    this.handleCreateorUpdateItem = this.handleCreateorUpdateItem.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleOpenDeletePopup = this.handleOpenDeletePopup.bind(this)
    this.handleDeleteClose = this.handleDeleteClose.bind(this)
    this.handleCloseAlert = this.handleCloseAlert.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.cancelCheckVoucher = this.cancelCheckVoucher.bind(this)
    this.cancelCheckVoucherSubmit = this.cancelCheckVoucherSubmit.bind(this)
    this.activeCheckVoucherSubmit = this.activeCheckVoucherSubmit.bind(this)
    this.onFileChange = this.onFileChange.bind(this)
    this.openAttachment = this.openAttachment.bind(this)
    this.handleAttachmentSubmit = this.handleAttachmentSubmit.bind(this)
    this.handleAttactmentInputChange = this.handleAttactmentInputChange.bind(this)
    this.activeJournal = this.activeJournal.bind(this)
    this.cancelJournal = this.cancelJournal.bind(this)
    this.activeJournalSubmit = this.activeJournalSubmit.bind(this)
    this.cancelJournalSubmit = this.cancelJournalSubmit.bind(this)
    this.reverseJournal = this.reverseJournal.bind(this)
    this.handleReverseJournal=this.handleReverseJournal.bind(this)
    this.closeReverseJournal = this.closeReverseJournal.bind(this)
    this.changePrintLayout = this.changePrintLayout.bind(this)
    this.openPrint = this.openPrint.bind(this)
    this.changeSign = this.changeSign.bind(this)
    this.submitChangeSign = this.submitChangeSign.bind(this)
    this.handleInputChangePDF = this.handleInputChangePDF.bind(this)
    this.openPrintPDF = this.openPrintPDF.bind(this)
  }

  componentDidMount() {
    var skip = false
    if (this.props.location.state !== undefined) {
      if(this.props.location.state.item){
        skip = true
      }
    }
    this.loadCheckVoucher('/v1/check_vouchers/' + this.props.match.params.id,skip)
  }

  loadCheckVoucher(url,skip) {
    const id = this.props.match.params.id
    if(skip){
      this.setState({checkVoucher: this.props.location.state.item})
      this.setState({load: true})
    }else
   { axios({
      method: 'get',
      url: url,
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {
      this.setState({checkVoucher: resp.data})
      this.setState({load: true})
    }).catch(() => {
      this.setState({
        isOpen: true,
        message: " Unexpected Error Occurred",
        type: 'error'
      })
    })
  }
    axios({
      method: 'get',
      url: '/v1/check_vouchers/' + id + '/check_voucher_contents',
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {
      this.setState({checkVoucherContents: resp.data})
      if(resp.data.filter(item=>item.account.name==="Withholding Tax - Expanded").length>0){
        this.setState({withHoldingTaxAmt: resp.data.filter(item=>item.account.name==="Withholding Tax - Expanded")[0].amount_credit})
      }
    })
    axios({
      method: 'get',
      url: '/v1/check_vouchers/' + id + '/journal_entries',
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {
      this.setState({journalEntries: resp.data})
    })
    axios({
      method: 'get',
      url: '/v1/check_vouchers/' + id + '/attachments',
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {
      this.setState({attachments: resp.data})

    })

  }

  handleSubmit() {
    const item = this.state.checkVoucher
    var method = ''
    var url = ''
    if (item.id === '') {
      method = 'post'
      url = '/v1/check_vouchers/'
    } else {
      method = 'put'
      url = '/v1/check_vouchers/' + item.id
    }
    axios({
      method: method,
      url: url,
      data: (item),
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {
      const newData = resp.data
      this.setState({
        checkVouchers: this.state.checkVouchers.filter(checkVoucher => checkVoucher.id !== item.id)
      })
      this.setState({checkVouchers: [...this.state.checkVouchers, newData]})
      this.setState({
        isOpen: true,
        message: 'Submitted Successfully',
        type: 'success'
      })
      this.setState({openPopup: false})
    }).catch(error => {
      if (error.response.status === 422) {
        this.setState({error_messages: 'Name ' + error.response.data.errors.name[0]})
      } else {
        this.setState({
          isOpen: true,
          message: error.response.status.toString() + " Unexpected Error Occurred",
          type: 'error'
        })
        this.setState({openPopup: false})
      }
    })
  }

  handleDeleteItem() {
    const DeleteItemId = this.state.checkVoucher.id
    axios({
      method: 'delete',
      url: '/v1/check_vouchers/' + DeleteItemId,
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(() => {
      this.setState({
        checkVouchers: this.state.checkVouchers.filter(item => item.id !== DeleteItemId)
      })
      this.setState({
        isOpen: true,
        message: 'Deleted Successfully',
        type: 'error'
      })
      this.setState({openDeletePopup: false})
      this.setState({redirectToIndex: true})
    }).catch(error => {
      this.setState({
        isOpen: true,
        message: error.response.status.toString() + " Unexpected Error Occurred",
        type: 'error'
      })
    })
  }

  handleClose() {
    this.setState({openPopup: false})
    this.setState({openDeletePopup: false})
    this.setState({error_messages: ''})
    this.setState({cancelCheckVoucherPopup: false})
    this.setState({activeCheckVoucherPopup: false})
    this.setState({openAttachmentPopup: false})
    this.setState({activeJournalPopup: false})
    this.setState({cancelJournalPopup: false})
    this.setState({ openPrintPopup: false, changeSignPopup: false, signatory: {...this.state.signatory, creator: ''},openPrintPDFPopup: false })
  }

  handleCreateorUpdateItem(item, isAdd, model) {
    var textTitle = ''
    if (isAdd) {
      textTitle = 'Create ' + model
    } else {
      textTitle = 'Edit ' + model
    }
    this.setState({openPopup: true})
    this.setState({checkVoucher: item})
    this.setState({title: textTitle})
  }

  handleInputChange(e) {
    this.setState({
      checkVoucher: {
        ...this.state.checkVoucher,
        [e.target.name]: e.target.value
      }
    })
  }

  handleChange(event, newValue) {
    this.setState({value: newValue})
  }

  handleOpenDeletePopup(model) {
    this.setState({model: model})
    this.setState({checkVoucher: this.state.checkVoucher})
    this.setState({openDeletePopup: true})
  }


  cancelCheckVoucher() {
    this.setState({cancelCheckVoucherPopup: true})
    this.setState({activeCheckVoucherPopup: true})
  }

  openAttachment() {
    this.setState({openAttachmentPopup: true})
  }

  activeJournal() {
    this.setState({activeJournalPopup: true})
  }

  cancelJournal() {
    this.setState({cancelJournalPopup: true})
  }

  cancelCheckVoucherSubmit() {
    const id = this.props.match.params.id
    axios({
      method: 'put',
      url: '/v1/check_vouchers/' + id + '/cancel',
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then((resp) => {
      this.setState({checkVoucher: resp.data})
      this.setState({
        isOpen: true,
        message: 'Submitted Successfully',
        type: 'success'
      })
      this.setState({cancelCheckVoucherPopup: false})
    })

  }

  activeCheckVoucherSubmit() {
    const id = this.props.match.params.id
    axios({
      method: 'put',
      url: '/v1/check_vouchers/' + id + '/activate',
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then((resp) => {
      this.setState({checkVoucher: resp.data})
      this.setState({
        isOpen: true,
        message: 'Submitted Successfully',
        type: 'success'
      })
      this.setState({activeCheckVoucherPopup: false})
    })
  }


  activeJournalSubmit() {
    const id = this.state.checkVoucher.journal_id
    axios({
      method: 'put',
      url: '/v1/journals/' + id + '/post',
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(() => {
      axios({
        method: 'get',
        url: '/v1/check_vouchers/' + this.props.match.params.id,
        headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
      }).then(resp => {
        this.setState({checkVoucher: resp.data, isOpen: true, message: 'Submitted Successfully', type: 'success',activeJournalPopup: false})
      })
      this.setState({
        isOpen: true,
        message: 'Submitted Successfully',
        type: 'success'
      })
      this.setState({activeJournalPopup: false})
    })
  }

  cancelJournalSubmit() {
    const id = this.state.checkVoucher.journal_id
    axios({
      method: 'put',
      url: '/v1/journals/' + id + '/unpost',
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(() => {
      axios({
        method: 'get',
        url: '/v1/check_vouchers/' + this.props.match.params.id,
        headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
      }).then(resp => {
        this.setState({checkVoucher: resp.data, isOpen: true, message: 'Submitted Successfully', type: 'success',cancelJournalPopup: false})
      })
      this.setState({
        isOpen: true,
        message: 'Submitted Successfully',
        type: 'success'
      })
      this.handleClose()
    })
  }

  reverseJournal() {
    const id = this.state.checkVoucher.journal_id
    axios({
      method: 'post',
      url: '/v1/journals/' + id + '/reverse_entries',
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(() => {
      setTimeout(() => {
        axios({
          method: 'get',
          url: '/v1/check_vouchers/' + this.props.match.params.id + '/journal_entries',
          headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
        }).then(resp => {
          this.setState({journalEntries: resp.data, isOpen: true, message: 'Submitted Successfully', type: 'success',reverseJournalPopUp: false})
        })
      }, 1000)
    })
  }

  handleDeleteClose() {
    this.setState({openDeletePopup: false})
  }

  handleCloseAlert(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      isOpen: false
    })
  }

  onFileChange(e) {
    e.stopPropagation()
    e.preventDefault()
    this.setState({
      attachment: {
        ...this.state.attachment,
        file: e.target.files[0],
        title: e.target.files[0].name
      }
    })
  }

  handleAttachmentSubmit() {
    const id = this.props.match.params.id
    const item = this.state.attachment
    const file = item.file
    const title = item.file.name
    const description = item.description
    const formdata = new FormData()
    formdata.append('file', file)
    formdata.append('title', title)
    formdata.append('description', description)

    axios({
      method: 'post',
      url: '/v1/check_vouchers/' + id + '/attachments',
      data: (formdata),
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {
      const newData = resp.data
      this.setState({
        attachments: this.state.attachments.filter(attach => attach.id !== item.id)
      })
      this.setState({attachments: [...this.state.attachments, newData]})
      this.setState({
        isOpen: true,
        message: 'Submitted Successfully',
        type: 'success'
      })
      this.setState({openAttachmentPopup: false})
    }).catch(error => {
      this.setState({
        isOpen: true,
        message: error.response.status.toString() + " Unexpected Error Occurred",
        type: 'error'
      })
    })
  }

  handleAttactmentInputChange(e) {
    this.setState({
      attachment: {
        ...this.state.attachment,
        [e.target.name]: e.target.value
      }
    })
  }

  handleReverseJournal(){
    this.setState({reverseJournalPopUp: true})
  }

  closeReverseJournal(){
    this.setState({reverseJournalPopUp: false})
  }

  openPrint() {
    this.setState({ openPrintPopup: true })
  }
  
  openPrintPDF() {
    this.setState({ openPrintPDFPopup: true })
  }
  
  changeSign() {
    this.setState({ changeSignPopup: true })
  }

  changePrintLayout() {
    return new Promise((resolve) => {
      this.setState({ forPrint: !this.state.forPrint}, () => resolve());
    });
  } 

  handleInputChangePDF(e) {
    this.setState({ signatory: {  ...this.state.signatory, [e.target.name]: e.target.value  } })
  }

  submitChangeSign() {
    this.setState({checkVoucher: {...this.state.checkVoucher, creator: this.state.signatory.creator}, changeSignPopup: false})
  }


  render() {
    const {redirectToIndex} = this.state
    if (redirectToIndex)
     { if(this.props.match.params.payee_id){
        return (<Redirect to={'/payees/'+this.props.match.params.payee_id}/>)
      }else{
        return (<Redirect to={'/check_vouchers'}/>)
      }}
      
    const printPdf = <ReactToPrint
      trigger={() => {
        return <Button color="primary" variant='outlined'>No</Button>
      }}
      onBeforeGetContent={this.changePrintLayout}
      onAfterPrint={this.changePrintLayout}
      content={() => this.componentRef}
    />

    const ChangeSignPrintPdf = <ReactToPrint
      trigger={() => {
        return <Button color="primary" variant='outlined'>Proceed</Button>
      }}
      onBeforeGetContent={this.changePrintLayout}
      onAfterPrint={this.changePrintLayout}
      content={() => this.componentRef}
    />
    return (
      <>
        {
          this.state.load ? (
            <div ref={el => (this.componentRef = el)} className={this.state.forPrint ? styles.tableBodyHeight : null}>
              <CompanyHeader forPrint={this.state.forPrint}/>
              <div className={styles.showTitleHeader}>
                <div><h2>Check Voucher Information</h2></div>
                <div className={this.state.forPrint ? styles.noDisplay: null}>
                  <div className={styles.groupButtonShow}>
                    <Tooltip title="Back"><IconButton>
                      <a href={this.props.match.params.payee_id?'/payees/'+this.props.match.params.payee_id: '/check_vouchers'}>
                        <ArrowBackIosOutlinedIcon/>
                      </a>
                    </IconButton></Tooltip>
                    {
                    this.state.checkVoucher.can_edit?
                    <Tooltip title="Edit"><IconButton
                    name="edit_check_voucher"
                      color="primary"
                    >
                      <Link name="edit_check_voucher" to={{
                        pathname: `${this.state.checkVoucher.id}/edit`,
                        state: {
                          item: this.state.checkVoucher,
                        }
                      }}>
                        <EditIcon/>
                      </Link>
                    </IconButton></Tooltip>:null}
                    {this.state.checkVoucher.can_delete?<Tooltip title="Delete"><IconButton
                    name="delete_check_voucher"
                      color="secondary"
                      onClick={this.handleOpenDeletePopup}
                    >
                      <DeleteIcon/>
                    </IconButton></Tooltip>:null}
                  </div>
                </div>
              </div>
              <hr/>
              <div className={styles.detailsBody}>


              <Grid container spacing={1}>

                
                {/* <div className={styles.leftShowDetails}> */}
                <Grid item xs={this.state.forPrint ? 6 :12} sm={6} md={6} lg={6} className={styles.gridBody}>
                  <div className={styles.detailList}>
                    <div><b> Voucher Number </b></div>
                    <div>:&emsp; {this.state.checkVoucher.prefix?(this.state.checkVoucher.prefix):null}{this.state.checkVoucher.reference_no}</div>
                  </div>
                </Grid>
                <Grid item xs={this.state.forPrint ? 6 :12} sm={6} md={6} lg={6} className={styles.gridBody}>
                  <div className={styles.detailList}>
                    <div><b> Branch </b></div>
                    <div>:&emsp;&emsp; {this.state.checkVoucher.branch?this.state.checkVoucher.branch.name:null}</div>
                  </div>
                </Grid>
                <Grid item xs={this.state.forPrint ? 6 :12} sm={6} md={6} lg={6} className={styles.gridBody}>
                  <div className={styles.detailList}>
                    <div><b>Bank </b></div>
                    <div>:&emsp; {this.state.checkVoucher.bank?this.state.checkVoucher.bank.name:null} </div>
                  </div>
                </Grid>
                <Grid item xs={this.state.forPrint ? 6 :12} sm={6} md={6} lg={6} className={styles.gridBody}>
                  <div className={styles.detailList}>
                    <div><b>Payee </b></div>
                    <div>:&nbsp;&emsp;&emsp;
                      <a href={`/payees/${this.state.checkVoucher.payee? this.state.checkVoucher.payee.id:null}`} target="_blank"
                         rel="noopener noreferrer">
                        {this.state.checkVoucher.payee?this.state.checkVoucher.payee.name:null}
                      </a>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={this.state.forPrint ? 6 :12} sm={6} md={6} lg={6} className={styles.gridBody}>
                  <div className={styles.detailList}>
                    <div><b> Check Number </b></div>
                    <div>:&emsp; {this.state.checkVoucher.check_no} </div>
                  </div>
                </Grid>
                 <Grid item xs={this.state.forPrint ? 6 :12} sm={6} md={6} lg={6} className={styles.gridBody}>
                  <div className={styles.detailList}>
                    <div><b> Date of Check </b></div>
                    <div>:&emsp;&emsp; {moment(this.state.checkVoucher.date_of_check).format('L')} </div>
                  </div>
                </Grid>
                <Grid item xs={this.state.forPrint ? 6 :12} sm={6} md={6} lg={6} className={styles.gridBody}>
                  <div className={styles.detailList}>
                    <div><b> Amount </b></div>
                    <div>:&emsp; {parseFloat(this.state.checkVoucher.amount).toLocaleString(navigator.language, {minimumFractionDigits: 2})} </div>
                  </div>
                </Grid>
                <Grid item xs={this.state.forPrint ? 6 :12} sm={6} md={6} lg={6} className={styles.gridBody}>
                  <div className={styles.detailList}>
                    <div><b> Expense Type </b></div>
                    <div>:&emsp;&emsp; {this.state.checkVoucher.expense_type? this.state.checkVoucher.expense_type.name:null} </div>
                  </div>
                </Grid>
                <Grid item xs={this.state.forPrint ? 6 :12} sm={6} md={6} lg={6} className={styles.gridBody}>
                  <div className={styles.detailList}>
                    <div><b> Prepared By </b></div>
                    <div>:&emsp; {this.state.checkVoucher.creator} </div>
                  </div>
                </Grid>
                <Grid item xs={this.state.forPrint ? 6 :12} sm={6} md={6} lg={6} className={styles.gridBody}>
                  <div className={styles.detailList}>
                    <div><b> Status </b></div>
                    <div>:&emsp;&emsp; {this.state.checkVoucher.status === 'active' ? (this.state.checkVoucher.post_status) :
                      <span style={{color: 'red'}}>{this.state.checkVoucher.status}</span>}   </div>
                  </div>
                </Grid>
                <Grid item xs={12} lg={12} className={styles.gridBody}>
                  <div className={styles.detailListCustom}>
                    <div><b> Amount in Words</b></div>
                    <div className={styles.valueDetails}>
                      :&emsp;&emsp;
                      <div><b>
                        <i>

                          {this.state.checkVoucher.amount > 0 ?
                            (
                              <div>
                                {converter.toWords(this.state.checkVoucher.amount.toString().split('.')[0])}
                                {/*+*/}
                                {this.state.checkVoucher.amount.toString().split('.').length === 2 && this.state.checkVoucher.amount.toString().split('.')[1].toString !== null ?
                                  (
                                    <span>
                                          <span className={styles.wordAnd}> and </span>
                                      {this.state.checkVoucher.amount.toString().split('.')[1]}
                                      {(this.state.checkVoucher.amount.toString().split('.')[1].length === 1 ? ('0') : ('')).toString()}
                                      /100 Pesos Only
                                      </span>
                                  ) : ''}
                              </div>

                            )
                            : null}
                        </i>
                      </b>
                      </div>
                    </div>
                  </div>
                </Grid>
              
                <Grid item xs={12} lg={12} className={styles.gridBody}>
                  <div className={styles.detailList}>
                    <div><b> Particulars</b></div>
                    <div className={styles.valueDetails}>:&emsp;&emsp;
                      <div>{this.state.checkVoucher.remarks}</div>
                    </div>
                  </div>
                </Grid>
                </Grid>
              </div>
              <hr/>
              <div>
                <Tabs value={this.state.value} onChange={this.handleChange} indicatorColor={this.state.forPrint? "": "secondary"}
                    className={styles.tabBody} variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example">
                  <Tab className={this.state.openPrintPDFPopup? styles.noDisplay: styles.tabList} label={<div className={styles.tabListTitle}>
                    {/*<PersonOutlineIcon /> */}
                    <span className={styles.tabLabel}>CHECK VOUCHER DETAILS</span></div>}{...a11yProps(0)} />
                  <Tab className={this.state.openPrintPopup? styles.noDisplay:styles.tabList} label={<div className={styles.tabListTitle}>
                    {/*<MenuBookIcon />*/}
                    <span className={styles.tabLabel}>JOURNAL ENTRIES</span></div>}{...a11yProps(1)} />
                  <Tab className={this.state.forPrint? styles.noDisplay : styles.tabList} label={<div className={styles.tabListTitle}>
                    {/*<CalendarTodayIcon />*/}
                    <span className={styles.tabLabel}>ATTACHMENTS</span></div>} {...a11yProps(2)} />
                    <Tab className={this.state.forPrint? styles.noDisplay : styles.tabList} label={<div className={styles.tabListTitle}>
                    <span className={styles.tabLabel}>BILLS</span></div>} {...a11yProps(3)} />
                    <Tab className={this.state.forPrint? styles.noDisplay : styles.tabList} label={<div className={styles.tabListTitle}>
                    <span className={styles.tabLabel}>AUDIT LOGS</span></div>} {...a11yProps(4)} />
                </Tabs>
                <TabPanel value={this.state.value} index={0} className={styles.tabListBody}>
                  <CheckVoucherContentDetailTab
                    checkVoucherContents={this.state.checkVoucherContents}
                    cancelCheckVoucher={this.cancelCheckVoucher}
                    checkVoucher={this.state.checkVoucher}
                    forPrint={this.state.forPrint}
                    openPrint={this.openPrint}
                  />
                </TabPanel>
                <TabPanel value={this.state.value} index={1} className={styles.tabListBody}>
                  <JournalEntriesDetailsTab
                    checkVoucher={this.state.checkVoucher}
                    journalEntries={this.state.journalEntries}
                    activeJournal={this.activeJournal}
                    cancelJournal={this.cancelJournal}
                    handleReverseJournal={this.handleReverseJournal}
                    openPrint={this.openPrintPDF}
                    forPrint={this.state.forPrint}
                    />
                </TabPanel>
                <TabPanel value={this.state.value} index={2} className={styles.tabListBody}>
                  <AttachmentDetailTab
                    attachments={this.state.attachments}
                    openAttachment={this.openAttachment}
                  />
                </TabPanel>
                <TabPanel value={this.state.value} index={3} className={styles.tabListBody}>
                  <Bills
                    model='check_vouchers'
                    itemId={this.state.checkVoucher.id}
                  />
                </TabPanel>
                <TabPanel value={this.state.value} index={4} className={styles.tabListBody}>
                  <AuditLog gid={this.state.checkVoucher.gid}/>
                </TabPanel>
              </div>

              <SimplePopUp
                openPopup={this.state.checkVoucher.status === 'active' ? this.state.activeCheckVoucherPopup : this.state.cancelCheckVoucherPopup}
                title="Check Voucher"
                items={this.state.checkVouchers}
                handleClose={this.handleClose}
                maxWidth={this.state.maxWidth}
              >
                {this.state.checkVoucher.status === 'active' ? (
                  <div>
                  <span>
                    <b>Would you like to cancel this Check Voucher?</b>
                  </span>
                    <div align="right" className={styles.actionButton}><Button color="primary" variant="outlined"
                                                                               onClick={this.cancelCheckVoucherSubmit}
                    >save</Button></div>
                  </div>
                ) : <div>
                  <span>
                    <b>Would you like to activate this Check Voucher?</b>
                  </span>
                  <div align="right" className={styles.actionButton}>
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={this.activeCheckVoucherSubmit}
                    >save</Button></div>
                </div>
                }
              </SimplePopUp>
              
              <SimplePopUp
                openPopup={this.state.cancelJournalPopup}
                title="Confirmation"
                items={this.state.checkVouchers}
                handleClose={this.handleClose}
                maxWidth={this.state.maxWidth}
              >
               
                  <div>
                  <span>
                    <b>Would you like to unpost this Journal?</b>
                  </span>
                    <div align="right" className={styles.actionButton}>
                      <Button
                        color="primary"
                        variant="outlined"
                        onClick={this.cancelJournalSubmit}
                      >save</Button>
                    </div>
                  </div>
                
              </SimplePopUp>
              <SimplePopUp
                openPopup={this.state.activeJournalPopup}
                title="Confirmation"
                items={this.state.checkVouchers}
                handleClose={this.handleClose}
                maxWidth={this.state.maxWidth}
              >
                <div>
                  <span>
                    <b>Would you like to post this Journal?</b>
                  </span>
                      <div
                        align="right"
                        className={styles.actionButton}>
                        <Button
                          color="primary"
                          variant="outlined"
                          onClick={this.activeJournalSubmit}
                        >save</Button></div>
                </div>
              </SimplePopUp>
             
              <SimplePopUp
                openPopup={this.state.openAttachmentPopup}
                title="Upload Attachments"
                items={this.state.attachments}
                handleClose={this.handleClose}
                maxWidth={this.state.maxWidth}
              >
                <AttachmentForm
                  attachment={this.state.attachment}
                  error={this.state.error_messages} item={this.state.checkVoucher}
                  onFileChange={this.onFileChange}
                  submit={this.handleAttachmentSubmit} onchange={this.handleAttactmentInputChange}/>
              </SimplePopUp>
              <SimpleDeletePopUp
                openDeletePopup={this.state.openDeletePopup}
                item={this.state.checkVoucher}
                delete={this.handleDeleteItem}
                handleDeleteClose={this.handleClose}
                model="Check Voucher"
              />
              <AlertMessage
                notify={this.state.notify}
                handleCloseAlert={this.handleCloseAlert}
                isOpen={this.state.isOpen}
                type={this.state.type}
                message={this.state.message}
              />
               <SimplePopUp
                openPopup={this.state.reverseJournalPopUp}
                title="Confirmation"
                handleClose={this.closeReverseJournal}
                maxWidth='sm' 
              >
                 <div>
                  <span>
                    <b>Would you like to reverse this Journal&apos;s entries?</b>
                  </span>
                    <div align="right" className={styles.actionButton}>
                      <Button
                        color="primary"
                        variant="outlined"
                        onClick={this.reverseJournal}
                      >yes</Button>
                    </div>
                  </div>
              </SimplePopUp>

              <SimplePopUp
                openPopup={this.state.openPrintPopup? this.state.openPrintPopup : this.state.openPrintPDFPopup}
                title={this.state.openPrintPopup? "Print Check Voucher" : "Print Journal Entries"}
                items={this.state.bill}
                handleClose={this.handleClose}
                maxWidth={this.state.maxWidth}
              > 
                <div>
                <span>
                  <b>Do you want to overwrite sinagtory person?</b>
                </span>
                <div align="right" className={styles.actionButton}>
                    <div>
                      {printPdf}
                    </div>
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={this.changeSign}
                    >Yes</Button></div>
                </div>
              </SimplePopUp>

              <SimplePopUp
                openPopup={this.state.changeSignPopup}
                title="Change Signatory"
                items={this.state.bill}
                handleClose={this.handleClose}
                maxWidth={this.state.maxWidth}
              > 
                <form>
                <TextField
                  autoComplete="off"
                  className={styles.textFields}
                  variant="outlined"
                  size="small"
                  name="creator"
                  fullWidth="true"
                  label="Name"
                  onChange={this.handleInputChangePDF}
                  value={this.state.signatory.creator}
                  id="bill-name"
                />
                </form>
                <div align="right" className={styles.actionButton}>
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={this.handleClose}
                    >Cancel</Button>
                    <div onClick={this.submitChangeSign}>{ChangeSignPrintPdf}</div> 
                </div>
              </SimplePopUp>

              <footer className={this.state.forPrint?null:styles.noDisplay} >
                <div className={styles.signLine}></div>
                <h3 >{this.state.checkVoucher.creator}</h3>
              </footer> 
            </div>
          ) : (
            <ProgressBar model={this.state.model}/>
          )}
      </>
    )
  }
}

export default CheckVoucherShow
CheckVoucherShow.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.object.isRequired
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
      payee_id: PropTypes.string
    })
  })
}
