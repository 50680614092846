import React from 'react'

import PropTypes from 'prop-types'
import styles from '../Billing.module.css'

import {
  Table, TableHead, TableBody, TableCell, TableRow,TableContainer
} from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import CancelIcon from '@material-ui/icons/Cancel';
import PrintIcon from '@material-ui/icons/Print';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import Tooltip from '@material-ui/core/Tooltip'
import LocalAtmOutlinedIcon from '@material-ui/icons/LocalAtmOutlined';

class CheckVoucherContentDetailTab extends React.Component {

  getTotalDebit () {
    var totalDebit = 0
    if(this.props.checkVoucherContents.length>0){
      this.props.checkVoucherContents.map((content)=>{
        totalDebit = parseFloat(totalDebit) + parseFloat(content.amount_debit)
      })
    }
    return totalDebit
  }

  getTotalCredit () {
    var totalCredit = 0
    if(this.props.checkVoucherContents.length>0){
      this.props.checkVoucherContents.map((content)=>{
        totalCredit = parseFloat(totalCredit) + parseFloat(content.amount_credit)
      })
    }
    return totalCredit
  }
  render() {
    const conditionfields = ['amount_credit','amount_debit']
    return (
      <div>
        <div className={this.props.forPrint ? styles.noDisplay : styles.checkDetailsHeader}>
      
          <div className={styles.groupButtonTab}>

            {this.props.isBillPayment? (
            <Tooltip title="Apply Payment" arrow>
              <IconButton onClick={this.props.applyPayment}>

                <LocalAtmOutlinedIcon/>
              </IconButton>
            </Tooltip>
            ):null}
            {this.props.checkVoucher.status === 'active' ? (
                <Tooltip title="Cancel Check Voucher" arrow>
                  <IconButton
                    onClick={this.props.cancelCheckVoucher}
                  >
                    <CancelIcon/>
                  </IconButton>
                </Tooltip>
              ) :
              <Tooltip title="Active Check Voucher" arrow>
                <IconButton
                  onClick={this.props.cancelCheckVoucher}
                >
                  <CheckCircleOutlineIcon/>
                </IconButton>
              </Tooltip>}

            <Tooltip title="Print Check" arrow>
            <IconButton name="print_check"
            >
              <a href={this.props.checkVoucher.links !== undefined? this.props.checkVoucher.links.pdfs.show : ''} target="_blank"
                 rel="noopener noreferrer">
                <CreditCardIcon/>
              </a>
            </IconButton>
            </Tooltip>
            <Tooltip title="Print Check Voucher" arrow>

                <IconButton
                  onClick={this.props.openPrint}
                >
              <PrintIcon/>
            </IconButton>
            </Tooltip>
          </div>
        </div>
        <TableContainer style={{maxHeight: '575px', position: 'relative'}}>
        <Table size="small" stickyHeader aria-label="customized sticky table">
          <TableHead>
            <TableRow>
              <TableCell>
                <b> Account Code</b>
              </TableCell>
              <TableCell>
                <b>Account Title</b>
              </TableCell>
              <TableCell align="right">
                <b>Debit</b>
              </TableCell>
              <TableCell align="right">
                <b>Credit</b>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
          {
              conditionfields.map((field)=>(
                this.props.checkVoucherContents.sort(function (a, b) {
                  if (a.account.code.toLowerCase() < b.account.code.toLowerCase()) return -1;
                  if (a.account.code.toLowerCase() > b.account.code.toLowerCase()) return 1;
                  return 0;
                }).map((content) => (
                  content[field]==="0.0" &&
                  <TableRow key={content.id}>
                    <TableCell>
                      {content.account_code}
                    </TableCell>
    
                    <TableCell>
                      {content.account.name}
                    </TableCell>
                    <TableCell align="right">
                      {parseFloat(content.amount_debit).toLocaleString(navigator.language, {minimumFractionDigits: 2})}
                    </TableCell>
                    <TableCell align="right">
                      {parseFloat(content.amount_credit).toLocaleString(navigator.language, {minimumFractionDigits: 2})}
                    </TableCell>
                  </TableRow>
                ))
              ))
            }
            <TableRow>
              <TableCell></TableCell>
              <TableCell align="right"><b> Total :</b></TableCell>
              <TableCell align="right"><b>{this.getTotalDebit().toLocaleString(navigator.language, {minimumFractionDigits: 2})}</b></TableCell>
              <TableCell align="right"><b>{this.getTotalCredit().toLocaleString(navigator.language, {minimumFractionDigits: 2})}</b></TableCell>
            </TableRow>
          </TableBody>
        </Table>
        </TableContainer>

      </div>
    )
  }
}

export default CheckVoucherContentDetailTab

CheckVoucherContentDetailTab.propTypes = {
  classes: PropTypes.object,
  checkVoucher: PropTypes.object,
  checkVoucherContents: PropTypes.array,
  cancelCheckVoucher: PropTypes.func,
  applyPayment: PropTypes.func,
  printPdf: PropTypes.func,
  openPrint: PropTypes.func,
  isBillPayment: PropTypes.bool,
  forPrint: PropTypes.bool,
}
