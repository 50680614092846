import React from 'react'
import styles from './Billing.module.css'

import axios from "axios";
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import ProgressBar from '../../shared/ProgressBar/ProgressBar';
import { Redirect } from 'react-router'

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import {
  Grid, Tooltip, Typography, Box, Button,TextField
} from '@material-ui/core'
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import CheckVoucherContentDetailTab from "../Billing/CheckVoucherTabs/CheckVoucherContentDetailTab";
import JournalEntriesDetailsTab from "../Billing/CheckVoucherTabs/JournalEntriesDetailTab";
import AttachmentDetailTab from "../CheckVoucher/CheckVoucherTabs/AttachmentDetailTab";
import AttachmentForm from "../CheckVoucher/Attachments/AttachmentForm";
import SimplePopUp from "../../shared/SimplePopUp/SimplePopUp";
import MoneyOutlinedIcon from '@material-ui/icons/MoneyOutlined';
import NoteOutlinedIcon from '@material-ui/icons/NoteOutlined';
import moment from "moment";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from '@material-ui/icons/Edit'
import SimpleDeletePopUp from "../../shared/SimpleDeletePopUp/SimpleDeletePopUp";
import DeleteIcon from '@material-ui/icons/Delete'
import converter from "number-to-words";
import AuditLog from './../../shared/AuditLog/AuditLog'
import ReactToPrint from "react-to-print";
import CompanyHeader from '../../shared/CompanyHeader/CompanyHeader'

function TabPanel(props) {
  const { children, value, index, ...other } = props
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

class BillingShow extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      model: 'bill',
      bill: {},
      value: 0,
      attachments: [],
      journalEntries: [],
      applyPaymentPopup: false,
      openDeletePopup: false,
      redirectToIndex: false,
      cancelCheckVoucherPopup: false,
      openPrintPopup: false,
      changeSignPopup: false,
      openPrintPDFPopup: false,
      activeCheckVoucherPopup: false,
      activeJournalPopup: false,
      cancelJournalPopup: false,
      openReverseJournalPopUp: false,
      checkVoucherContents: [],
      openAttachmentPopup: false,
      forPrint: false,
      attachment: { title: '', file: '', description: '' },
      checkVoucher: {},
      signatory: {},
      checkVoucherbills: []
    }
    this.handleChange = this.handleChange.bind(this)
    this.openAttachment = this.openAttachment.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.cancelCheckVoucher = this.cancelCheckVoucher.bind(this)
    this.applyPayment = this.applyPayment.bind(this)
    this.handleDeleteItem = this.handleDeleteItem.bind(this)
    this.handleOpenDeletePopup = this.handleOpenDeletePopup.bind(this)
    this.cancelBillSubmit = this.cancelBillSubmit.bind(this)
    this.activeBillSubmit = this.activeBillSubmit.bind(this)
    this.activeJournal = this.activeJournal.bind(this)
    this.cancelJournal = this.cancelJournal.bind(this)
    this.activeJournalSubmit = this.activeJournalSubmit.bind(this)
    this.cancelJournalSubmit = this.cancelJournalSubmit.bind(this)
    this.handleAttacthmentSubmit = this.handleAttacthmentSubmit.bind(this)
    this.handleAttactmentInputChange = this.handleAttactmentInputChange.bind(this)
    this.onFileChange = this.onFileChange.bind(this)
    this.handleReverseJournal = this.handleReverseJournal.bind(this)
    this.reverseJournal = this.reverseJournal.bind(this)
    this.closeReverseJournal = this.closeReverseJournal.bind(this)
    this.changePrintLayout = this.changePrintLayout.bind(this)
    this.openPrint = this.openPrint.bind(this)
    this.changeSign = this.changeSign.bind(this)
    this.submitChangeSign = this.submitChangeSign.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.openPrintPDF = this.openPrintPDF.bind(this)
  }

  componentDidMount() {

    var skip = false
    if (this.props.location.state !== undefined) {
      if (this.props.location.state.item) {
        skip = true
      }
    }
    this.loadBill('/v1/bills/' + this.props.match.params.bill_id, skip)
  }

  loadBill(url, skip) {
    if (skip) {
      this.setState({ bill: this.props.location.state.item, load: true })
    } else {
      axios({
        method: 'get',
        url: url,
        headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
      }).then(resp => {
        this.setState({ bill: resp.data, load: true })
      })
    }

    axios({
      method: 'get',
      url: url + '/contents',
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ checkVoucherContents: resp.data })
    })
    axios({
      method: 'get',
      url: url + '/attachments',
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ attachments: resp.data })
    })
    axios({
      method: 'get',
      url: url + '/journal_entries',
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ journalEntries: resp.data })
    })
    this.setState({ load: true })
  }

  handleOpenDeletePopup(model) {
    this.setState({ model: model })
    this.setState({ bill: this.state.bill })
    this.setState({ openDeletePopup: true })
  }

  handleClose() {
    this.setState({ openPopup: false })
    this.setState({ openAttachmentPopup: false })
    this.setState({ openDeletePopup: false })
    this.setState({ applyPaymentPopup: false })
    this.setState({ activeJournalPopup: false })
    this.setState({ cancelCheckVoucherPopup: false })
    this.setState({ activeCheckVoucherPopup: false, openPrintPopup: false, changeSignPopup: false, signatory: {...this.state.signatory, creator: ''},openPrintPDFPopup: false })
  }

  openAttachment() {
    this.setState({ openAttachmentPopup: true })
  }

  handleChange(event, newValue) {
    this.setState({ value: newValue })
  }

  cancelCheckVoucher() {
    this.setState({ cancelCheckVoucherPopup: true })
    this.setState({ activeCheckVoucherPopup: true })
  }

  applyPayment() {
    this.setState({ applyPaymentPopup: true })
  }

  handleDeleteItem() {
    const DeleteItemId = this.state.bill.id
    axios({
      method: 'delete',
      url: '/v1/bills/' + DeleteItemId,
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(() => {
      this.setState({ redirectToIndex: true })
    })
  }

  cancelBillSubmit() {
    const id = this.props.match.params.bill_id
    axios({
      method: 'put',
      url: '/v1/bills/' + id + '/cancel',
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then((resp) => {
      this.setState({ bill: resp.data, cancelCheckVoucherPopup: false, isOpen: true, message: 'Submitted Successfully', type: 'success' })
    })
  }

  activeBillSubmit() {
    const id = this.props.match.params.bill_id
    axios({
      method: 'put',
      url: '/v1/bills/' + id + '/activate',
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then((resp) => {
      this.setState({ bill: resp.data, activeCheckVoucherPopup: false, isOpen: true, message: 'Submitted Successfully', type: 'success' })
    })
  }

  activeJournal() {
    this.setState({ activeJournalPopup: true })
  }

  cancelJournal() {
    this.setState({ cancelJournalPopup: true })
  }

  activeJournalSubmit() {
    const id = this.state.bill.journal_id
    axios({
      method: 'put',
      url: '/v1/journals/' + id + '/post',
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then((resp) => {
      this.setState({ bill: { ...this.state.bill, journal: resp.data, post_status: "Posted", activeJournalPopup: false, isOpen: true, message: 'Submitted Successfully', type: 'success' } })
    })
  }

  cancelJournalSubmit() {
    const id = this.state.bill.journal_id
    axios({
      method: 'put',
      url: '/v1/journals/' + id + '/unpost',
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then((resp) => {
      this.setState({ bill: { ...this.state.bill, journal: resp.data, post_status: "Not Posted" } })
      this.setState({ activeJournalPopup: false })
      this.setState({
        isOpen: true,
        message: 'Submitted Successfully',
        type: 'success'
      })
    })
  }

  handleAttacthmentSubmit() {
    const id = this.props.match.params.bill_id
    const item = this.state.attachment
    const file = item.file
    const title = item.file.name
    const description = item.description
    const formdata = new FormData()
    formdata.append('file', file)
    formdata.append('title', title)
    formdata.append('description', description)

    axios({
      method: 'post',
      url: '/v1/bills/' + id + '/attachments',
      data: (formdata),
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      const newData = resp.data
      this.setState({
        attachments: this.state.attachments.filter(attach => attach.id !== item.id)
      })
      this.setState({ attachments: [...this.state.attachments, newData] })
      this.setState({
        isOpen: true,
        message: 'Submitted Successfully',
        type: 'success'
      })
      this.setState({ openAttachmentPopup: false })
    })
  }

  handleAttactmentInputChange(e) {
    this.setState({
      attachment: {
        ...this.state.attachment,
        [e.target.name]: e.target.value
      }
    })
  }

  onFileChange(e) {
    this.setState({
      attachment: {
        ...this.state.attachment,
        file: e.target.files[0],
        title: e.target.files[0].name
      }
    })
  }

  handleReverseJournal() {
    this.setState({ openReverseJournalPopUp: true })
  }
  closeReverseJournal() {
    this.setState({ openReverseJournalPopUp: false })
  }

  reverseJournal() {
    const id = this.state.bill.journal_id
    axios({
      method: 'post',
      url: '/v1/journals/' + id + '/reverse_entries',
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(() => {
      setTimeout(() => {
        axios({
          method: 'get',
          url: '/v1/bills/' + this.props.match.params.bill_id + '/journal_entries',
          headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        }).then(resp => {
          this.setState({ journalEntries: resp.data, isOpen: true, message: 'Submitted Successfully', type: 'success', openReverseJournalPopUp: false })
        })
      }, 1000)
    })
  }
  
  openPrint() {
    this.setState({ openPrintPopup: true })
  }
  
  openPrintPDF() {
    this.setState({ openPrintPDFPopup: true })
  }
  
  changeSign() {
    this.setState({ changeSignPopup: true })
  }

  changePrintLayout() {
    return new Promise((resolve) => {
      this.setState({ forPrint: !this.state.forPrint}, () => resolve());
    });
  } 

  handleInputChange(e) {
    this.setState({ signatory: {  ...this.state.signatory, [e.target.name]: e.target.value  } })
  }

  submitChangeSign() {
    this.setState({bill: {...this.state.bill, creator: this.state.signatory.creator}, changeSignPopup: false, openPrintPopup: false})
  }

  render() {
    const { redirectToIndex } = this.state
    if (redirectToIndex)
      return (<Redirect to={'/bills'} />)

    const printPdf = <ReactToPrint
      trigger={() => {
        return <Button color="primary" variant='outlined'>No</Button>
      }}
      onBeforeGetContent={this.changePrintLayout}
      onAfterPrint={this.changePrintLayout}
      content={() => this.componentRef}
    />
    
    const ChangeSignPrintPdf = <ReactToPrint
      trigger={() => {
        return <Button color="primary" variant='outlined'>Proceed</Button>
      }}
      onBeforeGetContent={this.changePrintLayout}
      onAfterPrint={this.changePrintLayout}
      content={() => this.componentRef}
    />
    return (
      <>
        {
          this.state.load ? (
            <div ref={el => (this.componentRef = el)} className={this.state.forPrint ? styles.tableBodyHeight : null}>
              <CompanyHeader forPrint={this.state.forPrint}/>
              <div className={styles.showTitleHeader}>
                <div className={styles.titleBack}><h2 style={{ margin: '0' }}>  Bill Information </h2>
                  <div className={this.state.forPrint ? styles.noDisplay : styles.buttonBody}>
                    <Tooltip title="Back"><Link to={`/bills`}>< ArrowBackIosIcon fontSize="small" /></Link></Tooltip>
                    {
                      this.state.bill.can_edit ?
                        <Tooltip title="Edit"><IconButton
                          name="edit_bill"
                          color="primary"
                        >
                          <Link name="edit_billr" to={{
                            pathname: `${this.state.bill.id}/edit`,
                            state: {
                              item: localStorage.setItem('data_bill', JSON.stringify(this.state.bill))
                            }
                          }}>
                            <EditIcon />
                          </Link>
                        </IconButton></Tooltip> : null}
                    <Tooltip title="Delete"><IconButton
                      name="delete_bill"
                      color="secondary"
                      onClick={this.handleOpenDeletePopup}
                    >
                      <DeleteIcon />
                    </IconButton></Tooltip>
                  </div></div>
                <hr />
                <Grid container spacing={1}>

                  <Grid item xs={this.state.forPrint ? 6 :12} sm={6} md={6} lg={6} className={styles.gridBody}>
                    <div className={styles.detailList}>
                      <div><b> Payee Name </b></div>
                      <div>:&nbsp; {this.state.bill.payee ? this.state.bill.payee.name : ''} </div>
                    </div>
                  </Grid>

                  <Grid item xs={this.state.forPrint ? 6 :12} sm={6} md={6} lg={6} className={styles.gridBody}>
                    <div className={styles.detailList}>
                      <div><b> Reference No. </b></div>
                      <div>:&nbsp; {this.state.bill.reference_no} </div>
                    </div>
                  </Grid>
                  <Grid item xs={this.state.forPrint ? 6 :12} sm={6} md={6} lg={6} className={styles.gridBody}>
                    <div className={styles.detailList}>
                      <div><b> Amount</b></div>
                      <div>:&nbsp; {parseFloat(this.state.bill.amount).toLocaleString(navigator.language, { minimumFractionDigits: 2 })} </div>
                    </div>
                  </Grid>
                  <Grid item xs={this.state.forPrint ? 6 :12} sm={6} md={6} lg={6} className={styles.gridBody}>

                    <div className={styles.detailList}>
                      <div><b> Filed at </b></div>
                      <div>:&nbsp; {moment(this.state.bill.filed_at).format('L')} </div>
                    </div>
                  </Grid>
                  <Grid item xs={this.state.forPrint ? 6 :12} sm={6} md={6} lg={6} className={styles.gridBody}>
                    <div className={styles.detailList}>
                      <div><b> Paid Status</b></div>
                      <div>:&nbsp; {this.state.bill.is_paid === true ? 'Paid' : 'Not yet paid'}  </div>
                    </div>
                  </Grid>
                  <Grid item xs={this.state.forPrint ? 6 :12} sm={6} md={6} lg={6} className={styles.gridBody}>

                    <div className={styles.detailList}>
                      <div><b>Due Date</b></div>
                      <div>:&nbsp; {moment(this.state.bill.due_at).format('L')} </div>
                    </div>
                  </Grid>
                  <Grid item xs={this.state.forPrint ? 6 :12} sm={6} md={6} lg={6} className={styles.gridBody}>

                    <div className={styles.detailList}>
                      <div><b> Branch </b></div>
                      <div>:&nbsp; {this.state.bill.branch !== undefined ? this.state.bill.branch.name : ''} </div>
                    </div>
                  </Grid>
                  <Grid item xs={this.state.forPrint ? 6 :12} sm={6} md={6} lg={6} className={styles.gridBody}>

                    <div className={styles.detailList}>
                      <div><b> Taxable </b></div>
                      <div>:&nbsp; {this.state.bill.taxable === true ? 'Yes' : 'No'} </div>
                    </div>
                  </Grid>


                  <Grid item xs={this.state.forPrint ? 6 :12} sm={6} md={6} lg={6} className={styles.gridBody}>

                    <div className={styles.detailListCustom}>
                      <div><b> Amount in Words</b></div>
                      <div className={styles.valueDetails}>
                        :&emsp;&emsp;
                        <div><b>
                          <i>

                            {this.state.bill.amount > 0 ?
                              (
                                <div>
                                  {converter.toWords(this.state.bill.amount.toString().split('.')[0])}
                                  {/*+*/}
                                  {this.state.bill.amount.toString().split('.').length === 2 && this.state.bill.amount.toString().split('.')[1].toString !== null ?
                                    (
                                      <span>
                                        <span className={styles.wordAnd}> and </span>
                                        {this.state.bill.amount.toString().split('.')[1]}
                                        {(this.state.bill.amount.toString().split('.')[1].length === 1 ? ('0') : ('')).toString()}
                                        /100 Pesos Only
                                      </span>
                                    ) : ''}
                                </div>

                              )
                              : null}
                          </i>
                        </b>
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={this.state.forPrint ? 6 :12} sm={6} md={6} lg={6} className={styles.gridBody}>
                    <div className={styles.detailList}>
                      <div><b> Expense Type </b></div>
                      <div>:&nbsp; {this.state.bill.expense_type !== undefined && this.state.bill.expense_type !== null ? this.state.bill.expense_type.name : ''} </div>
                    </div>
                    <div className={styles.detailList}>
                      <div><b> Status </b></div>
                      <div>:&emsp;&emsp; {this.state.bill.status === 'active' ? (this.state.bill.post_status) :
                        <span style={{ color: 'red' }}>{this.state.bill.status}</span>}   </div>
                    </div>

                  </Grid>

                  <Grid item xs={this.state.forPrint ? 6 :12} sm={6} md={6} lg={6} className={this.state.forPrint ? styles.noDisplay : styles.gridBody}>
                    <div className={styles.detailList}>
                      <div><b> Prepared By </b></div>
                      <div>:&nbsp;&emsp; {this.state.bill.creator} </div>
                    </div>
                  </Grid>

                </Grid>

                <div className={styles.detailListRemarks} >
                  <div><b> Particulars </b></div>
                  <div>:&nbsp; {this.state.bill.remarks} </div>
                </div>
              </div>
              <hr />

              <Tabs value={this.state.value} onChange={this.handleChange} indicatorColor={this.state.forPrint? "": "secondary"}
                className={styles.tabBody} variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                <Tab className={this.state.openPrintPDFPopup? styles.noDisplay: styles.tabList} label={<div className={styles.tabListTitle}>
                  {/*<PersonOutlineIcon /> */}
                  <span className={styles.tabLabel}><b>BILLS DETAILS</b></span></div>}{...a11yProps(0)} />
                <Tab className={this.state.openPrintPopup? styles.noDisplay:styles.tabList} label={<div className={styles.tabListTitle}>
                  {/*<MenuBookIcon />*/}
                  <span className={styles.tabLabel}><b>JOURNAL ENTRIES</b></span></div>}{...a11yProps(1)} />
                <Tab className={this.state.forPrint? styles.noDisplay : styles.tabList} label={<div className={styles.tabListTitle}>
                  {/*<CalendarTodayIcon />*/}
                  <span className={styles.tabLabel}><b>ATTACHMENTS</b></span></div>} {...a11yProps(2)} />
                <Tab className={this.state.forPrint? styles.noDisplay : styles.tabList} label={<div className={styles.tabListTitle}>
                  <span className={styles.tabLabel}><b>AUDIT LOGS</b></span></div>} {...a11yProps(3)} />
              </Tabs>
              <TabPanel value={this.state.value} index={0} className={styles.tabListBody}>
                <CheckVoucherContentDetailTab
                  checkVoucherContents={this.state.checkVoucherContents}
                  cancelCheckVoucher={this.cancelCheckVoucher}
                  applyPayment={this.applyPayment}
                  checkVoucher={this.state.bill}
                  isBillPayment={true}
                  forPrint={this.state.forPrint}
                  openPrint={this.openPrint}
                />
              </TabPanel>
              <TabPanel value={this.state.value} index={1} className={styles.tabListBody}>
                <JournalEntriesDetailsTab
                  checkVoucher={this.state.bill}
                  journalEntries={this.state.journalEntries}
                  activeJournal={this.activeJournal}
                  cancelJournal={this.cancelJournal}
                  handleReverseJournal={this.handleReverseJournal}
                  openPrint={this.openPrintPDF}
                  forPrint={this.state.forPrint}
                />
              </TabPanel>
              <TabPanel value={this.state.value} index={2} className={styles.tabListBody}>
                <AttachmentDetailTab
                  attachments={this.state.attachments}
                  openAttachment={this.openAttachment}
                />
              </TabPanel>
              <TabPanel value={this.state.value} index={3} className={styles.tabListBody}>
                <AuditLog gid={this.state.bill.gid} />
              </TabPanel>

              <SimplePopUp
                openPopup={this.state.openAttachmentPopup}
                title="Upload Attachments"
                items={this.state.attachments}
                handleClose={this.handleClose}
                maxWidth={this.state.maxWidth}
              >
                <AttachmentForm
                  attachment={this.state.attachment}
                  error={this.state.error_messages} item={this.state.bill}
                  onFileChange={this.onFileChange}
                  submit={this.handleAttacthmentSubmit} onchange={this.handleAttactmentInputChange} />
              </SimplePopUp>
              <SimpleDeletePopUp
                openDeletePopup={this.state.openDeletePopup}
                item={this.state.bill}
                delete={this.handleDeleteItem}
                handleDeleteClose={this.handleClose}
                model="Bill"
              />

              {
                this.state.bill.journal !== null ? (
                  <SimplePopUp
                    openPopup={this.state.bill.journal !== undefined ? this.state.bill.journal.is_posted === false ? this.state.activeJournalPopup : this.state.cancelJournalPopup : null}
                    title="Journal"
                    items={this.state.bills}
                    handleClose={this.handleClose}
                    maxWidth={this.state.maxWidth}
                  >
                    {this.state.bill.journal !== undefined ? this.state.bill.journal.is_posted === false ? (
                      <div>
                        <span>
                          <b>Would you like to post this Journal?</b>
                        </span>
                        <div
                          align="right"
                          className={styles.actionButton}>
                          <Button
                            color="primary"
                            variant="outlined"
                            onClick={this.activeJournalSubmit}
                          >save</Button></div>
                      </div>
                    ) :
                      <div>
                        <span>
                          <b>Would you like to unpost this Journal?</b>
                        </span>
                        <div align="right" className={styles.actionButton}>
                          <Button
                            color="primary"
                            variant="outlined"
                            onClick={this.cancelJournalSubmit}
                          >save</Button>
                        </div>
                      </div>
                      : null}
                  </SimplePopUp>
                ) : null
              }

              <SimplePopUp
                openPopup={this.state.bill.status === 'active' ? this.state.activeCheckVoucherPopup : this.state.cancelCheckVoucherPopup}
                title="Cash Voucher"
                items={this.state.bills}
                handleClose={this.handleClose}
                maxWidth={this.state.maxWidth}
              >
                {this.state.bill.status === 'active' ? (
                  <div>
                    <span>
                      <b>Would you like to cancel this Bill?</b>
                    </span>
                    <div align="right" className={styles.actionButton}><Button color="primary" variant="outlined"
                      onClick={this.cancelBillSubmit}
                    >save</Button></div>
                  </div>
                ) : <div>
                  <span>
                    <b>Would you like to activate this Bill?</b>
                  </span>
                  <div align="right" className={styles.actionButton}>
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={this.activeBillSubmit}
                    >save</Button></div>
                </div>
                }
              </SimplePopUp>

              <SimplePopUp
                openPopup={this.state.openReverseJournalPopUp}
                title="Confirmation"
                handleClose={this.closeReverseJournal}
                maxWidth='sm'
              >
                <div>
                  <span>
                    <b>Would you like to reverse this Journal&apos;s entries?</b>
                  </span>
                  <div align="right" className={styles.actionButton}>
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={this.reverseJournal}
                    >yes</Button>
                  </div>
                </div>
              </SimplePopUp>
              <SimplePopUp
                openPopup={this.state.applyPaymentPopup}
                title="Payment Method"
                items={this.state.attachments}
                handleClose={this.handleClose}
                maxWidth={this.state.maxWidth}
              >
                <div className={styles.paymentBody}>
                  <div>
                    <Link name="cash_bills" to={{
                      pathname: `/bills/${this.state.bill.id}/cash_vouchers/new`,
                      state: {
                        item: localStorage.setItem('data_bill', JSON.stringify(this.state.bill)),
                      }
                    }}>
                      <MoneyOutlinedIcon fontSize="large" />
                    </Link>
                    <div align="center">Cash</div>
                  </div>
                  <div>
                    <Link to={{
                      pathname: `/bills/${this.state.bill.id}/check_voucher/new`,
                      state: {
                        item: localStorage.setItem('data_bill', JSON.stringify(this.state.bill)),
                      }
                    }}>
                      <NoteOutlinedIcon />
                    </Link>
                    <div align="center"> Check</div>
                  </div>
                </div>
              </SimplePopUp>
              
              <SimplePopUp
                openPopup={this.state.openPrintPopup? this.state.openPrintPopup : this.state.openPrintPDFPopup}
                title={this.state.openPrintPopup? "Print Check Voucher" : "Print Journal Entries"}
                items={this.state.bill}
                handleClose={this.handleClose}
                maxWidth={this.state.maxWidth}
              > 
                <div>
                <span>
                  <b>Do you want to overwrite sinagtory person?</b>
                </span>
                <div align="right" className={styles.actionButton}>
                    <div>
                      {printPdf}
                    </div>
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={this.changeSign}
                    >Yes</Button></div>
                </div>
              </SimplePopUp>

              <SimplePopUp
                openPopup={this.state.changeSignPopup}
                title="Change Signatory"
                items={this.state.bill}
                handleClose={this.handleClose}
                maxWidth={this.state.maxWidth}
              > 
                <form>
                <TextField
                  autoComplete="off"
                  className={styles.textFields}
                  variant="outlined"
                  size="small"
                  name="creator"
                  fullWidth="true"
                  label="Name"
                  onChange={this.handleInputChange}
                  value={this.state.signatory.creator}
                  id="bill-name"
                />
                </form>
                <div align="right" className={styles.actionButton}>
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={this.handleClose}
                    >Cancel</Button>
                    <div onClick={this.submitChangeSign}>{ChangeSignPrintPdf}</div> 
                </div>
              </SimplePopUp>

              <footer 
              className={this.state.forPrint?null:styles.noDisplay}
              >
                <div className={styles.signLine}></div>
                <h3 >{this.state.bill.creator}</h3></footer> 
            </div>
          ) : (
            <ProgressBar model={this.state.model} />
          )
        }
      </>
    )
  }
}

export default BillingShow

BillingShow.propTypes = {
  onchange: PropTypes.func,
  item: PropTypes.object,
  submit: PropTypes.func,
  location: PropTypes.shape({
    state: PropTypes.object
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      bill_id: PropTypes.string.isRequired
    })
  })
}
