import axios from 'axios';

export function setupAxios(username = '') {
    let baseUrl;

    if (/demo/.test(username)) {
      baseUrl = 'https://demo-caes-api' + process.env.REACT_APP_DOMAIN;
    } else {
      switch (username) {
        case 'arielagni':
        case 'katunying':
        case 'jasmin.prejillana':
          baseUrl = process.env.REACT_APP_API_DOMAIN;
          break;
        case 'jasmin.prejillana@fhaoassociates.com':
          baseUrl = 'https://fhao-caes-api2' + process.env.REACT_APP_DOMAIN;
          break;
        case 'remelyn.talosig@fhaoassociates.com':
          baseUrl = 'https://fhao-caes-api3' + process.env.REACT_APP_DOMAIN;
          break;
        case 'marylin.arcilla@fhaoassociates.com':
          baseUrl = 'https://fhao-caes-api4' + process.env.REACT_APP_DOMAIN;
          break;
          case 'admin@louder.ph':
          baseUrl = 'https://fhao-caes-api5' + process.env.REACT_APP_DOMAIN;
          break;
        case 'estoninateddy@gmail.com':
          baseUrl = 'https://fhao-caes-api6' + process.env.REACT_APP_DOMAIN;
          break;
        case 'mguzman@patleacarl-chem.com.ph':
          baseUrl = 'https://fhao-caes-api7' + process.env.REACT_APP_DOMAIN;
          break;
        case 'cynthia.honorio@perceptions-inc.com':
          baseUrl = 'https://fhao-caes-api9' + process.env.REACT_APP_DOMAIN;
          break;
        case 'info@fhaoassociates.com':
          baseUrl = 'https://fhao-caes-api10' + process.env.REACT_APP_DOMAIN;
          break;
        default:
          baseUrl = 'https://lex-caes-api' + process.env.REACT_APP_DOMAIN;
          break;
      }
    }

    axios.defaults.baseURL = baseUrl;
}
