import React from 'react'

import PropTypes from 'prop-types'

import { Table, TableHead,Button,TableBody, TableCell, TableRow, Checkbox } from '@material-ui/core'

import styles from './CheckVoucher.module.css'
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import moment from 'moment'
class SelectBills extends React.Component {

	render() {
		return (
			<>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell className={styles.refNo}>Reference No.</TableCell>
							<TableCell className={styles.small}>Due Date</TableCell>
							<TableCell className={styles.particulars}>Particulars</TableCell>
							<TableCell className={styles.small}>Taxable</TableCell>
							<TableCell className={styles.small}>Input Vat</TableCell>
							<TableCell className={styles.amtheader}>Amount</TableCell>
							<TableCell className={styles.amount}><b>Select All
								<Checkbox
									name="select_all"
									onClick={(event) => this.props.selectAllBills(event)}
									checked={this.props.selectedBills.length === this.props.bills.length}
								/></b></TableCell>
						</TableRow>


					</TableHead>
					<TableBody>
						{
							this.props.bills.length > 0 ? (
								this.props.bills.map((bill, idx) => (
									<TableRow key={idx}>
										<TableCell className={styles.refNo}>{bill.reference_no}</TableCell>
										<TableCell className={styles.small}>{moment(bill.due_at).format('MM/DD/YY')}</TableCell>
										<TableCell className={styles.particulars}>{bill.remarks}</TableCell>
										<TableCell className={styles.small}>{bill.taxable?<CheckIcon />:<ClearIcon />}</TableCell>
										<TableCell className={styles.small}>{bill.with_input_vat?<CheckIcon />:<ClearIcon />}</TableCell>
										<TableCell className={styles.amount}>{parseFloat(bill.amount).toLocaleString(navigator.language, { minimumFractionDigits: 2 })}</TableCell>
										<TableCell className={styles.amount}>
											<Checkbox
												checked={this.props.selectedBills.indexOf(bill.id) > -1}
												onClick={(event) => this.props.handleCheckBills(event, bill)}
											/>

										</TableCell>
									</TableRow>
								))
							) : null
						}

					</TableBody>
				</Table>
				<div className={styles.actionButton}>
					<Button variant="outlined"
						onClick={this.props.closeBills}>Done</Button>
					
				</div>
			</>
		)
	}
}

export default SelectBills

SelectBills.propTypes = {
	idx: PropTypes.number,
	bills: PropTypes.array,
	handleCheckBills: PropTypes.func,
	selectedBills: PropTypes.array,
	selectAllBills: PropTypes.func,
	closeBills: PropTypes.func
}
